import "./Form-tout.css";
import Header from "./HeaderPage.js";
import "./HeaderFR.css";
import programmeTout from "./img/programme-tout.png";
import programmeToutMobile from "./img/programme-tout-mobile.png";
import TitleArrow from "./TitlewithArrow";
import { useForm } from "react-hook-form";
import checkLine from "./img/check-line.png";
import { useState } from "react";
import api from "./api";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import getErrorDic from "./getErrorDic";
import swal, { redirectSwalFr } from "./swal";
import Footer from "./Footer.js";

const getErrorMessage = getErrorDic("fr");

export default function FormInscription() {
  return (
    <div>
      <Header />

      <div className="body">
        <Programme></Programme>
        <EndRegistration></EndRegistration>
        {/* <Form></Form>  */}
      </div>
      <Footer></Footer>
    </div>
  );
}

function Programme() {
  return (
    <div className="programme">
      <img className="programme-long" src={programmeTout}></img>
      <img className="programme-long-mobile" src={programmeToutMobile}></img>
    </div>
  );
}

function Form() {
  return (
    <div className="title-form">
      <div>
        <div className="title">
          <h1 className="title-h1">Unigrains célèbre ses 60 ans </h1>
          <h2 className="title-h2">
            le jeudi 5 octobre 2023 aux Salles du Carrousel du Louvre
          </h2>

          <p className="title-p">
            Nous nous réjouissons de vous retrouver pour marquer nos 60 ans de
            valeurs communes et réfléchir ensemble aux enjeux de notre filière
            agricole et agroalimentaire !
          </p>
          <h3 className="title-h3">Merci de confirmer votre participation</h3>
        </div>
      </div>
      <div className="div-form-infos">
        <FormInfos></FormInfos>
      </div>
    </div>
  );
}

function EndRegistration() {
  return(
    <div className="title-form">
      <div>
        <div className="title">
          <h1 className="title-h1">Unigrains célèbre ses 60 ans </h1>
          <h2 className="title-h2">
            le jeudi 5 octobre 2023 aux Salles du Carrousel du Louvre
          </h2>

          <p className="title-p">
            Nous nous réjouissons de vous retrouver pour marquer nos 60 ans de
            valeurs communes et réfléchir ensemble aux enjeux de notre filière
            agricole et agroalimentaire !
          </p>

          <p className="title-p-bold">Les inscriptions pour la Célébration des 60 ans d’Unigrains sont désormais clôturées.</p>
          
          <p className="title-p">Pour toute information complémentaire ou pour modifier votre inscription, merci de nous contacter à l’adresse : 
          <a className="title-a" href="mailto:contact@unigrains.fr"> contact@unigrains.fr </a></p>
          
          </div>
          </div>
          </div>
  )
}

function FormInfos() {
  const location = useLocation();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [participateCocktail, setParticipateCocktail] = useState();
  const [participateTour, setParticipateTour] = useState();
  const [participateConference, setParticipateConference] = useState();
  const [participateDiner, setParticipateDiner] = useState();
  const [foodSpecificity, setFoodSpecificity] = useState();
  const [isLoading, setIsLoading] = useState();
  const [participate, setParticipate] = useState();

  const checkEmailForm = async (email) => {
    try {
      const res = await api.get("/checkMailForm", {
        params: { email },
      });
      if (res.data.isNightOnly) {
        redirectSwalFr({
          text: "Vous n'êtes pas sur la bonne page.",
          url: "/celebration",
        });
      }
    } catch (e) {
      console.log(e);
      if (e === "emailNotFound") {
        swal(getErrorMessage(e));
      }
    }
  };

  const onSubmit = async (data) => {
    if (participate === false) {
      navigate("/60/confirmation-later");
    } else if (participate === true) {
      try {
        setIsLoading(true);
        await api.post("/inscription", {
          ...data,
          participateCocktail,
          participateTour,
          participateConference,
          participateDiner,
          foodSpecificity,
        });
        navigate("/60/confirmation");
      } catch (e) {
        swal(getErrorMessage(e));
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-info">
      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">Je participe</p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Oui"
            inputValue={true}
            value={participate}
            setValue={setParticipate}
          />
          <CustomCheckBox
            label="Je ne sais pas encore"
            inputValue={false}
            value={participate}
            setValue={setParticipate}
          />
        </div>
      </div>
      {participate ? (
        <>
          <div className="div-input-double">
            <InputForm
              inputName={"firstName"}
              register={register}
              inputWidth="50%"
              label="Prénom"
            />
            <InputForm
              inputName={"lastName"}
              register={register}
              inputWidth="50%"
              label="Nom"
            />
          </div>
          <div>
            <InputForm
              inputName={"email"}
              register={register}
              label="Mail"
              bottomText={
                "Rentrez l'email sur lequel vous avez reçu l'invitation"
              }
              onBlur={checkEmailForm}
            />
            <InputForm
              inputName={"company"}
              register={register}
              label="Entreprise"
            />
          </div>

          <CheckboxForm
            participateCocktail={participateCocktail}
            participateTour={participateTour}
            participateConference={participateConference}
            participateDiner={participateDiner}
            foodSpecificity={foodSpecificity}
            setParticipateCocktail={setParticipateCocktail}
            setParticipateTour={setParticipateTour}
            setParticipateConference={setParticipateConference}
            setParticipateDiner={setParticipateDiner}
            setFoodSpecificity={setFoodSpecificity}
            register={register}
          ></CheckboxForm>
        </>
      ) : (
        ""
      )}

      <div className="div-btn">
        <button disabled={isLoading} className="btn-end-form">
          Valider
          {isLoading ? (
            <TailSpin
              height="18"
              width="18"
              color="#000000"
              radius="0"
              wrapperStyle={{
                display: "block",
                margin: "0 auto",
                borderColor: "red",
              }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            ""
          )}
        </button>
      </div>
    </form>
  );
}

function CheckboxForm({
  register,
  participateCocktail,
  participateTour,
  participateConference,
  participateDiner,
  foodSpecificity,
  setParticipateCocktail,
  setParticipateTour,
  setParticipateConference,
  setParticipateDiner,
  setFoodSpecificity,
}) {
  return (
    <div>
      <Cocktail
        setParticipateCocktail={setParticipateCocktail}
        participateCocktail={participateCocktail}
      ></Cocktail>
      <Louvre
        participateTour={participateTour}
        setParticipateTour={setParticipateTour}
      ></Louvre>
      <Celebration
        participateConference={participateConference}
        participateDiner={participateDiner}
        setParticipateConference={setParticipateConference}
        setParticipateDiner={setParticipateDiner}
      ></Celebration>
      <Food
        foodSpecificity={foodSpecificity}
        setFoodSpecificity={setFoodSpecificity}
        register={register}
      ></Food>
      <EndForm register={register}></EndForm>
    </div>
  );
}

function Cocktail({ participateCocktail, setParticipateCocktail }) {
  return (
    <div>
      <TitleArrow value="12h à 16h : Cocktail déjeunatoire & échanges stratégiques"></TitleArrow>
      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">Je souhaite participer</p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Oui"
            inputValue={true}
            value={participateCocktail}
            setValue={setParticipateCocktail}
          />
          <div className="align-checkbox">
            <CustomCheckBox
              label="Non"
              inputValue={false}
              value={participateCocktail}
              setValue={setParticipateCocktail}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Louvre({ participateTour, setParticipateTour }) {
  return (
    <div>
      <TitleArrow
        value={`16h à 18h : Visite guidée du Louvre, parcours exceptionnel "Les Céréales dans l'Art"`}
      ></TitleArrow>
      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">Je souhaite participer</p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Oui"
            inputValue={true}
            value={participateTour}
            setValue={setParticipateTour}
          />
          <div className="align-checkbox">
            <CustomCheckBox
              label="Non"
              inputValue={false}
              value={participateTour}
              setValue={setParticipateTour}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Celebration({
  participateConference,
  setParticipateConference,
  participateDiner,
  setParticipateDiner,
}) {
  return (
    <div>
      <TitleArrow value="18h à minuit : Célébration des 60 ans d'Unigrains "></TitleArrow>
      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">
            Conférence : Unigrains aujourd'hui et demain
          </p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Oui"
            inputValue={true}
            value={participateConference}
            setValue={setParticipateConference}
          />
          <div className="align-checkbox">
            <CustomCheckBox
              label="Non"
              inputValue={false}
              value={participateConference}
              setValue={setParticipateConference}
            />
          </div>
        </div>
      </div>
      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">Dîner placé & concert privé</p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Oui"
            inputValue={true}
            value={participateDiner}
            setValue={setParticipateDiner}
          />
          <div className="align-checkbox">
            <CustomCheckBox
              label="Non"
              inputValue={false}
              value={participateDiner}
              setValue={setParticipateDiner}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Food({ register, foodSpecificity, setFoodSpecificity }) {
  return (
    <div>
      <TitleArrow value="Spécificités alimentaires"></TitleArrow>

      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">
            Avez-vous des spécificités alimentaires ?{" "}
          </p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Oui"
            inputValue={true}
            value={foodSpecificity}
            setValue={setFoodSpecificity}
          />

          <div className="align-checkbox">
            <CustomCheckBox
              label="Non"
              inputValue={false}
              value={foodSpecificity}
              setValue={setFoodSpecificity}
            />
          </div>
        </div>
      </div>
      {foodSpecificity ? (
        <div className="end-form">
          <label htmlFor="haveSpecialFood">
            Indiquez vos spécificités alimentaires
          </label>
          <textarea
            className="input-end-form"
            {...register("haveSpecialFood")}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

function EndForm({ register }) {
  return (
    <div className="end-form">
      <label htmlFor="other">
        Avez-vous des précisions ou des questions à nous transmettre :
      </label>

      <textarea className="input-end-form" {...register("other")} />
    </div>
  );
}

function CustomCheckBox({ label, inputValue, value, setValue }) {
  const isChecked = inputValue === value;
  return (
    <div className="checkbox-wrapper" onClick={() => setValue(inputValue)}>
      <div className={isChecked ? "checkbox-checked" : "checkbox-unchecked"}>
        {isChecked ? (
          <img alt="" className="check-line" src={checkLine}></img>
        ) : (
          ""
        )}
      </div>
      <span>{label}</span>
    </div>
  );
}

function InputForm({
  label,
  inputWidth,
  inputName,
  register,
  onBlur,
  bottomText,
}) {
  return (
    <label style={{ width: inputWidth }} className="input-form">
      <span className="span-input">{label}</span>
      <input
        className="input-info"
        type="text"
        {...register(inputName)}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e.target.value);
          }
        }}
      ></input>
      {bottomText ? <span className="bottomText">{bottomText}</span> : ""}
    </label>
  );
}
