import {
  Stack,
  TableCell,
  TableRow,
  Box,
  Chip,
  capitalize,
  useTheme,
  IconButton,
} from "@mui/material";
import { User } from "../models/User";
import Iconify from "./Iconify";
import useAuthStore from "../../auth/useAuthStore";
export type Props = {
  item: User;
  isSearching: boolean;
  isSelected: boolean;
  onDeleteUser: (user: User) => void;
  onAcceptUser: (user: User) => void;
};

export const TableItemRow = ({
  item,
  isSearching,
  isSelected,
  onDeleteUser,
  onAcceptUser,
}: Props) => {
  const { currentUser } = useAuthStore();
  return (
    <TableRow
      sx={{
        backgroundColor:
          item.isValidatedDiner && currentUser.isAdmin ? "#519872" : undefined,
      }}
    >
      <TableCell sx={{ fontSize: 12, p: 0 }}>
        <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
          <Box
            sx={{
              width: 5,
              height: 30,
              backgroundColor: item.color,
            }}
          />
          <span>
            {item.lastName.toUpperCase()}
            {"\u00a0"}
            {capitalize(item.firstName)}
          </span>
        </Stack>
      </TableCell>
      <TableCell align={"center"} sx={{ p: 0 }}>
        {item.isVip && (
          <Chip
            label={"VIP"}
            size={"small"}
            color={"success"}
            disabled={!item.isVip}
            sx={{
              height: "auto",
              "& .MuiChip-label": {
                fontSize: 13,
              },
            }}
          />
        )}
      </TableCell>
      <TableCell align={"center"} sx={{ p: 0 }}>
        {!item.isFrench && (
          <Chip
            label={"EN"}
            size={"small"}
            disabled={item.isFrench}
            sx={{
              height: "auto",
              "& .MuiChip-label": {
                fontSize: 14,
                color: "#fff",
              },
              background: "linear-gradient(90deg, #0047AB 50%, #0047AB 50%)",
            }}
          />
        )}
      </TableCell>
      <TableCell sx={{ p: 0 }}>
        <Stack direction={"row"} spacing={0} justifyContent={"center"}>
          <IconButton size={"small"} onClick={() => onDeleteUser(item)}>
            <Iconify icon={"eva:close-outline"} color={"red"} />
          </IconButton>
          {!item.isValidatedDiner && currentUser.isAdmin && (
            <IconButton size={"small"} onClick={() => onAcceptUser(item)}>
              <Iconify icon={"eva:checkmark-outline"} color={"limegreen"} />
            </IconButton>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};
