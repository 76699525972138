import Header from "./HeaderPage";
import "./HeaderFR.css";
import "./ConfirmationFR.css";
import programmeTout from "./img/programme-tout.png";
import Footer from "./Footer.js";

export default function PageConfirmation() {
  return (
    <div>
      <Header></Header>
      <div className="body">
        {/* <Programme></Programme> */}

        <div className="title">
          <h1 className="title-h1">Unigrains célèbre ses 60 ans </h1>
          <h2 className="title-h2">
            le 5 octobre 2023 aux Salles du Carrousel du Louvre
          </h2>
          <p className="light" style={{ margin: "40px", fontSize: "20px" }}>
            Merci pour votre inscription !<br /> Vous recevrez une confirmation
            par e-mail.
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

function Programme() {
  return (
    <div className="programme">
      <img className="programme-long" src={programmeTout}></img>
    </div>
  );
}
