import Header from "./HeaderPage.js";
import TitleArrow from "../TitlewithArrow.js";

import TitlePages from "../BigTitle.js";
import "../AccesFR.css";
import planAccesPdf from "../img/PLAN ACCES AUTOMOBILES ET PIETONS_VEHICLES AND PEDESTRIANS ACCESS MAP_CDL_FR-EN_2018.pdf";
import Footer from "./Footer.js";

export default function AccesPage() {
  return (
    <div>
      <Header></Header>
      <Acces></Acces>
    </div>
  );
}

function Acces() {
  return (
    <div>
      <TitlePages value="Access the Carrousel du Louvre"></TitlePages>

      <div className="div-map">
        <div className="adress">
          <p className="bold">
            Address :{" "}
            <span className="light">
              99, rue de Rivoli - 75001 Paris, France
            </span>{" "}
          </p>
        </div>

        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.7615561896055!2d2.332257475199068!3d48.86275710040093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e671fe085c22e9%3A0xf4ef51f9f726df26!2sCarrousel%20du%20Louvre!5e0!3m2!1sfr!2sfr!4v1687273448716!5m2!1sfr!2sfr"
            width="450"
            height="350"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div className="adress">
          <p className="light">
            Download the map :{" "}
            <a
              href={planAccesPdf}
              target="_blank"
              rel="noopener noreferrer"
              className="yellow"
            >
              Link to PDF
            </a>{" "}
          </p>
        </div>
      </div>

      <div className="acces-paragraph">
        <div className="div-title-arrow">
          <TitleArrow value="Public transport"></TitleArrow>
        </div>
        <p className="bold">Bus</p>
        <p className="light">
          Lines 21, 27, 39, 48, 67, 68, 69, 72, 81, 95 Stop at Palais Royal -
          Musée du Louvre or Palais Royal -<br></br> Comédie Française or Musée
          du Louvre
        </p>
        <p className="bold">Metro</p>
        <p className="light">
          Liges 1 and 7, Palais Royal - Musée du Louvre Station - exit #6
        </p>

        <div className="div-title-arrow">
          <TitleArrow value="Plane"></TitleArrow>
        </div>
        <p className="bold">
          From Charles de Gaulle Airport
          <span>
            - 45 minutes by taxi <br /> RER B direction Saint-Rémy-lès-Chevreuse
            to Châtelet - Les Halles, then metro Line 1 direction La Défense to
            Palais Royal - Musée du Louvre
          </span>
        </p>

        <div className="div-title-arrow">
          <TitleArrow value="Taxi"></TitleArrow>
        </div>
        <div className="taxis">
          <p className="light">
            G7 <br />
            Taxis bleus <br />
            Alpha taxis <br />
          </p>
          <p className="light">
            3607 <br />
            3609 <br />
            01 45 85 85 85{" "}
          </p>
        </div>

        <div className="div-title-arrow">
          <TitleArrow value="Car"></TitleArrow>
        </div>
        <p className="bold">
          The Carrousel du Louvre Parking
          <span>
            {" "}
            is accessible via Avenue du Lemonnier, 75001 Paris
            <br />
            <br />
            · From Rue de Rivoli: turn left at the Place des Pyramides traffic
            light <br />· From the Quai des Tuileries: turn left at the
            Pont-Royal traffic light
            <br />
            · From Pont-Royal: straight on, direction Opéra, then keep right
            <br /> <br />
            In all three cases, enter the tunnel, follow signs for “Parc
            Carrousel Louvre”
          </span>
        </p>
        <p className="light">
          Parking du Carrousel
          <br />
          1, avenue du Général Lemonnier
          <br />
          75001 Paris
          <br />
          <br />
          <span className="bold">Other nearby carparks:</span>
          <br />
          <br />
          Parking Pyramides
          <br />
          15, rue des Pyramides
          <br />
          75001 Paris
          <br />
          <br />
          Parking Saint-Honoré
          <br />
          39, place du Marché Saint-Honoré
          <br />
          75001 Paris
          <br />
        </p>
      </div>
      <Footer></Footer>
    </div>
  );
}
