import "./Big-title.css";
import bigArrow from "./img/title-arrow.png";

export default function TitlePages({ value }) {
  return (
    <div className="title-pages">
      <img className="big-arrow" src={bigArrow}></img>
      <h1 className="title-big">{value}</h1>
    </div>
  );
}
