import { TopPage } from "./HeaderPage";
import TitlePages from "../BigTitle";
import "../Contact.css";

export default function HomePage() {
  return (
    <div>
      <TopPage></TopPage>
      <TitlePages value="Click on the link received by e-mail."></TitlePages>

      <div className="div-contact">
        <p>
          This is an invitation only event. <br />
          <br />
          Please click on the link received by e-mail!
          <br />
          <br />
        </p>
        <br />
        <br />
      </div>
    </div>
  );
}
