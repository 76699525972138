import "./Mentions-legales.css";
import Header from "./HeaderPage";
import TitlePages from "./BigTitle";
import TitleArrow from "./TitlewithArrow";
import Footer from "./Footer.js";

export default function MentionsLegalesPage() {
  return (
    <div>
      <Header></Header>

      <TitlePages value="Mentions Légales"></TitlePages>
      <div className="div-mentions-legales">
        <TitleArrow value="Editeur :"></TitleArrow>
        <p className="light">
          Arkone, SAS au capital de 5000 €
          <br />
          Siège social : 105, rue de Longchamp, 92200 Neuilly-Sur-Seine, France
          <br />
          RCS Nanterre : 892 975 368
          <br />
          Responsable de la publication : Sébastien Roques
        </p>
      </div>
      <div className="div-mentions-legales">
        <TitleArrow value="Hébergement :"></TitleArrow>
        <p className="light">
          Ce site est hébergé par OVH SAS :
          <br />
          RCS Lille : 424 761 419
          <br />2 Rue Kellermann 59100 ROUBAIX, FRANCE
        </p>
      </div>
      <Footer></Footer>
    </div>
  );
}
