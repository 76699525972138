import Header from "./HeaderPage";
import TitlePages from "./BigTitle";
import "./Contact.css"
import Footer from "./Footer.js";

export default function ContactPage(){
    return(
        <div>
            <Header></Header>
            <TitlePages value="Contact"></TitlePages>

            <div className="div-contact">
                <p>Pour toutes questions ou informations complémentaires <br/><br/>
n’hésitez pas à nous contacter au :<br/><br/>

+33 (0)6 69 20 79 19 <br/><br/>

ou par mail :<br/><br/>

<a href="mailto:contact@unigrains.fr">contact@unigrains.fr</a></p><br/><br/>
            </div>
            <Footer></Footer>
        </div>
    )
}