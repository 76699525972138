import React, { ReactElement, useEffect, useState, useRef } from "react";
import { Container, Grid, Box } from "@mui/material";
import { User } from "./plan/models/User";
import { TableSection } from "./plan/components/TableSection";
import { TableDataItem } from "./plan/models/TableDataItem";
import { UnplacedListSection } from "./plan/components/UnplacedListSection";
import Layout from "./plan/layouts";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { confirmationSwal, successSwal } from "./swal";
import { useNavigate } from "react-router-dom";
import api from "./api";
import { useForm } from "react-hook-form";
import errorSwal from "./swal";
import { FaTimes } from "react-icons/fa";
import Modal from "react-modal";
//@ts-ignore
import { generatePlanExcel } from "./ExportPlan.js";

PageHome.getLayout = function getLayout(page: ReactElement) {
  return <Layout variant={"logoOnly"}>{page}</Layout>;
};

type History = {
  tables: TableDataItem[];
  unplacedData: User[];
  placed: User[];
};

const colors: { [key: string]: string } = {
  Agriculture: "#7363b9",
  Entreprise: "#519872",
  Intermédiaire: "#D65108",
  Interne: "#FDB940",
  Investisseur: "#92DCE5",
};

export default function PageHome() {
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [historyIndex, setHistoryIndex] = React.useState(0);
  const [history, setHistory] = React.useState<History[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const isSavedRef = useRef(isSaved);
  useEffect(() => {
    isSavedRef.current = isSaved;
  }, [isSaved]);

  const getDefault = async () => {
    const participants = await api.get("/participants");
    const tables = await api.get("/getTables");
    const placed = [];
    participants.data = participants.data
      .filter((p: any) => p.participateDiner)
      .map((p: any) => ({
        ...p,
        job: p.sector,
        company: p.companyImport,
        owner: p.owner,
        color: colors[p.sector as keyof typeof colors],
      }));

    for (const table of tables.data.tables) {
      const users: TableDataItem[] = [];
      for (const u of table.data) {
        const index = participants.data.findIndex((p: any) => p._id === u._id);
        if (index !== -1) {
          users.push(participants.data[index]);
          placed.push(participants.data[index]);
          participants.data.splice(index, 1);
        }
      }
      table.data = users;
    }

    const unPlaced = [...participants.data];
    setHistoryIndex(0);
    setHistory([
      { tables: tables.data.tables, unplacedData: unPlaced, placed: placed },
    ]);
  };
  useEffect(() => {
    getDefault();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (!isSavedRef.current) {
        e.preventDefault();
        e.returnValue =
          "Attention, des éléments n'ont pas été sauvegardé !\nVoulez-vous vraiment quitter ? Les changements non sauvegardés seront perdus.";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const updateHistory = React.useCallback(
    (tables: TableDataItem[], unplacedData: User[], placed: User[]) => {
      const nextHistory = [
        ...history.slice(0, Math.max(0, historyIndex + 1)),
        {
          tables: [
            ...tables.map((table) => ({
              ...table,
              data: [...table.data],
            })),
          ],
          unplacedData: [...unplacedData],
          placed: [...placed],
        },
      ];
      setIsSaved(false);
      setHistory(nextHistory);
      setHistoryIndex(historyIndex + 1);
    },
    [history, historyIndex]
  );

  const handleHistoryBack = () => {
    const nextHistoryIndex = Math.max(0, historyIndex - 1);
    setHistoryIndex(nextHistoryIndex);
  };

  const handleHistoryForward = () => {
    const nextHistoryIndex = Math.min(history.length, historyIndex + 1);
    setHistoryIndex(nextHistoryIndex);
  };

  const curentHistoryItem = history[historyIndex];

  if (!curentHistoryItem || !curentHistoryItem.tables) {
    return <></>;
  }

  const handleDeleteTable = (tableToDelete: TableDataItem) => {
    const deletedUsersId = tableToDelete.data.map((u) => u._id);
    updateHistory(
      curentHistoryItem.tables.filter((table) => tableToDelete.id !== table.id),
      [...curentHistoryItem.unplacedData, ...tableToDelete.data],
      curentHistoryItem.placed.filter(
        (placed) => !deletedUsersId.includes(placed._id)
      )
    );
  };

  const automaticPlacement = () => {
    const curreentTables = [
      ...curentHistoryItem.tables.map((t) => ({ ...t, data: [...t.data] })),
    ];
    const getFreeTable = () => {
      for (const table of curreentTables) {
        if (table.data.length < 9) {
          return table;
        }
      }
    };
    const getNewTable = () => {
      const table = {
        id: curreentTables.length.toString(),
        name: "",
        data: [],
      };
      curreentTables.push(table);
      return table;
    };

    for (const user of curentHistoryItem.unplacedData) {
      const table = getFreeTable() || getNewTable();
      table.data.push({ ...user, isPending: true });
    }

    updateHistory(
      curreentTables,
      [],
      [...curentHistoryItem.unplacedData, ...curentHistoryItem.placed]
    );
  };

  const handleSave = async () => {
    setIsSaved(true);

    try {
      await api.post("/setTables", { tables: curentHistoryItem.tables });
      successSwal("Sauvegarde en cours.");
    } catch (e) {
      console.log(e);
    }
  };

  const leave = async () => {
    if (!isSaved) {
      const result = await confirmationSwal(
        "Attention, des éléments n'ont pas été sauvegardé",
        "Voulez-vous vraiment quitter ? Les changements non sauvegardés seront perdus."
      );

      if (result.isConfirmed) {
        navigate("/back-uni-office");
      }
    } else {
      navigate("/back-uni-office");
    }
  };
  return (
    <>
      <ModalAddParticipant
        showModal={showModal}
        setShowModal={setShowModal}
        refresh={getDefault}
      />
      <DndProvider backend={HTML5Backend}>
        <Container style={{ maxWidth: "90%" }}>
          <Grid container spacing={4} mb={5} mt={5}>
            <Grid item xs={9} sx={{ borderRight: "1px solid #F6F6F6" }}>
              <TableSection
                exportMethod={() => {
                  const exportData: any[] = [];

                  curentHistoryItem.tables.map((t: any, i: number) => {
                    t.data.map((u: any) => {
                      exportData.push({ ...u, table: i + 1 });
                    });
                  });

                  generatePlanExcel({
                    data: exportData.sort((a, b) =>
                      `${a.lastName}${a.firstName}`.localeCompare(
                        `${b.lastName}${b.firstName}`
                      )
                    ),
                    tables: curentHistoryItem.tables,
                  });
                }}
                onLeave={leave}
                setShowModal={setShowModal}
                tables={curentHistoryItem.tables}
                unplacedCount={curentHistoryItem.unplacedData.length}
                onDeleteTable={handleDeleteTable}
                onDeleteUser={(userToRemove) => {
                  updateHistory(
                    curentHistoryItem.tables.map((table) => ({
                      ...table,
                      data: table.data.filter(
                        (user) => user._id !== userToRemove._id
                      ),
                    })),
                    [
                      ...curentHistoryItem.unplacedData,
                      { ...userToRemove, isPending: false },
                    ],
                    [
                      ...curentHistoryItem.placed
                        .filter((placed) => placed._id !== userToRemove._id)
                        .map((u) => ({
                          ...u,
                          isPending: false,
                        })),
                    ]
                  );
                }}
                onEditName={({ name, tableId }) => {
                  updateHistory(
                    [
                      ...curentHistoryItem.tables.map((table) => {
                        let nameTable = table.name;
                        if (table.id === tableId) {
                          nameTable = name;
                        }
                        return { ...table, name: nameTable };
                      }),
                    ],
                    [...curentHistoryItem.unplacedData],
                    [...curentHistoryItem.placed]
                  );
                }}
                onAutomaticPlacement={automaticPlacement}
                onAcceptUser={async (userToAccept) => {
                  try {
                    await api.post("/validateDiner", {
                      userId: userToAccept._id,
                    });
                    successSwal("Le participant a été confirmé");
                    getDefault();
                  } catch (e) {
                    errorSwal(e);
                  }
                  console.log({ userToAccept });
                }}
                onChange={(nextTables) => {
                  updateHistory(
                    nextTables,
                    curentHistoryItem.unplacedData,
                    curentHistoryItem.placed
                  );
                }}
                onDrop={(id, table) => {
                  if (table.data.length >= 12) {
                    return;
                  }
                  const item = curentHistoryItem.unplacedData.find(
                    (unplacedItem) => unplacedItem._id === id
                  );

                  if (item) {
                    updateHistory(
                      [
                        ...curentHistoryItem.tables.map((tableItem) =>
                          tableItem.id === table.id
                            ? {
                                ...table,
                                data: [...table.data, { ...item }],
                              }
                            : tableItem
                        ),
                      ],
                      curentHistoryItem.unplacedData.filter(
                        (item) => item._id !== id
                      ),
                      [...curentHistoryItem.placed, item]
                    );
                  }
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <UnplacedListSection
                data={curentHistoryItem.unplacedData}
                placedData={curentHistoryItem.placed}
                canBackHistory={historyIndex > 0}
                canForwardHistory={
                  historyIndex > 0 && historyIndex + 1 < history.length
                }
                onBackHistory={handleHistoryBack}
                onSave={handleSave}
                onForwardHistory={handleHistoryForward}
                onChange={(nextUnplacedData) => {
                  updateHistory(
                    curentHistoryItem.tables,
                    nextUnplacedData,
                    curentHistoryItem.placed
                  );
                }}
              />
            </Grid>
          </Grid>
          <Box height={2} />
        </Container>
      </DndProvider>
    </>
  );
}

function ModalAddParticipant({
  showModal,
  setShowModal,
  refresh,
}: {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  refresh: () => void;
}) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      zIndex: 5,
    },
  };
  const [type, setType] = useState<string>("TOUT");
  const { register, handleSubmit, reset, setValue } = useForm();

  useEffect(() => {
    if (type === "SOIREE") {
      setValue("participateCocktail", false);
      setValue("participateTour", false);
    }
    setValue("type", type);
  }, [type]);

  const onSubmit = async (data: any) => {
    try {
      console.log(data);
      await api.post("/createParticipantPlan", data);
      successSwal("Le participant a été ajouté");
      setShowModal(false);
      refresh();
      reset();
    } catch (e) {
      errorSwal(e);
    }
  };
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      style={customStyles}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
        <div className="header-modal">
          <h1 className="title-modal">Ajouter un nouveau participant</h1>
          <button
            className="text-red-600 hover:text-red-900 mr-2"
            onClick={(e) => {
              setShowModal(false);
            }}
          >
            <FaTimes />
          </button>
        </div>

        <div className="div-input">
          <label className="label-new-participant">
            Nom :
            <input
              type="text"
              className="input-new-participant"
              {...register("lastName")}
            ></input>
          </label>
          <label className="label-new-participant">
            Prénom :
            <input
              type="text"
              className="input-new-participant"
              {...register("firstName")}
            ></input>
          </label>
        </div>
        <div className="div-input">
          <label className="label-new-participant">
            Civilité :
            <select className="select-new-particpant" {...register("civilite")}>
              <option value="Mme.">Mme</option>
              <option value="Mr">M.</option>
            </select>
          </label>
          <label className="label-new-participant">
            Langue :
            <select className="select-new-particpant" {...register("langue")}>
              <option value="FR">Francais</option>
              <option value="EN">Anglais</option>
            </select>
          </label>
        </div>
        <div className="div-input">
          <label className="label-new-participant">
            Email :
            <input
              type="text"
              className="input-new-participant"
              {...register("email")}
            ></input>
          </label>
          <label className="label-new-participant">
            Type :
            <select
              className="select-new-particpant"
              {...register("type")}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="TOUT">Tout</option>
              <option value="SOIREE">Soirée</option>
            </select>
          </label>
        </div>

        <div className="div-input">
          <label className="label-new-participant">
            Entreprise :
            <input
              type="text"
              className="input-new-participant"
              {...register("companyImport")}
            ></input>
          </label>
        </div>

        <div className="div-input">
          {type === "TOUT" && (
            <>
              {" "}
              <label className="label-new-participant-modal">
                <input type="checkbox" {...register("participateCocktail")} />
                Cocktail
              </label>
              <label className="label-new-participant-modal">
                <input type="checkbox" {...register("participateTour")} />
                Visite du louvre
              </label>
            </>
          )}
          <label className="label-new-participant-modal">
            <input type="checkbox" {...register("participateConference")} />
            Conférences
          </label>
          <label className="label-new-participant-modal">
            <input type="checkbox" {...register("participateDiner")} />
            Dîner + Concert
          </label>
        </div>

        <button className="button-modal">Ajouter</button>
      </form>
    </Modal>
  );
}
