import Swal from "sweetalert2";

const errorSwal = (text) =>
  Swal.fire({
    icon: "error",
    iconColor: "#F39200",
    title: "Oops...",
    text: text,
    confirmButtonColor: "#6168AE",
    confirmButtonText: "OK",
  });

export const successSwal = (text) =>
  Swal.fire({
    icon: "success",
    iconColor: "#F39200",
    title: "",
    text: text,
    confirmButtonColor: "#6168AE",
    confirmButtonText: "OK",
  });

export const confirmationSwal = (title, text) =>
  Swal.fire({
    title,
    text,
    icon: "warning",
    iconColor: "#F39200",
    showCancelButton: true,
    confirmButtonColor: "#F39200",
    cancelButtonColor: "#72767d",
    cancelButtonText: "Annuler",
    confirmButtonText: "Oui",
  });

export const redirectSwalFr = ({ text, url }) =>
  Swal.fire({
    icon: "error",
    iconColor: "#F39200",
    title: "Oops...",
    html: `${text} <br><a href="${url}">Cliquez ici pour aller sur le bon formulaire</a>`,
    confirmButtonColor: "#6168AE",
    confirmButtonText: "OK",
  });

export const redirectSwalEN = ({ text, url }) =>
  Swal.fire({
    icon: "error",
    iconColor: "#F39200",
    title: "Oops...",
    html: `${text} <br><a href="${url}">Click here to go to the correct form</a>`,
    confirmButtonColor: "#6168AE",
    confirmButtonText: "OK",
  });

export const confirmDeleteSwal = () =>
  Swal.fire({
    title: "Supprimer ?",
    text: "Voulez vous vraiment supprimer ce participant ?",
    icon: "warning",
    iconColor: "#E84E0F",
    showCancelButton: true,
    confirmButtonColor: "#E84E0F",
    cancelButtonColor: "#6168AE",
    confirmButtonText: "Oui, supprimer",
  });

export const editEmailSwal = async () =>
  Swal.fire({
    title: "Modifier une adresse email",
    input: "email",
    inputLabel: "Nouvelle adresse email :",
    inputPlaceholder: "Entrer la nouvelle adresse email",
    confirmButtonColor: "#F39200",
  });
export const longLoadingSwal = (text) =>
  Swal.fire({
    text: text,
    icon: "info",
    buttonsStyling: false,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });

export default errorSwal;
