import {
  Button,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  Box,
  Card,
  Divider,
} from "@mui/material";
import React from "react";
import Iconify from "../components/Iconify";
import { TableItem, TABLE_ITEM_HEIGHT } from "../components/TableItem";
import { TableDataItem } from "../models/TableDataItem";
import { searchTextInArray } from "../utils/searchTextInArray";
import { User } from "../models/User";
export type Props = {
  unplacedCount: number;
  tables: TableDataItem[];
  onChange: (nextTables: TableDataItem[]) => void;
  onDrop: (unplacedDataId: string, table: TableDataItem) => void;
  onDeleteTable: (table: TableDataItem) => void;
  onDeleteUser: (user: User) => void;
  onAcceptUser: (user: User) => void;
  onAutomaticPlacement: () => void;
  onLeave: () => void;
  onEditName: (data: { name: string; tableId: string }) => void;
  setShowModal: (val: boolean) => void;
  exportMethod: () => void;
};

export const TableSection = ({
  unplacedCount,
  tables,
  onChange,
  onDrop,
  onDeleteTable,
  onDeleteUser,
  onAcceptUser,
  onAutomaticPlacement,
  onLeave,
  onEditName,
  exportMethod,
  setShowModal,
}: Props) => {
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const [search, setSearch] = React.useState("");

  const placedCount = React.useMemo(
    () => tables.reduce((result, table) => result + table.data.length, 0),
    [tables]
  );

  const [tableContainerWidth, setTableContainerWidth] = React.useState(0);

  React.useEffect(() => {
    setTableContainerWidth(tableContainerRef.current?.clientWidth ?? 0);
    const listener = () => {
      setTableContainerWidth(tableContainerRef.current?.clientWidth ?? 0);
    };
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  const filteredTables = React.useMemo(() => {
    if (!search.trim()?.length) {
      return tables;
    }

    return tables.filter((table) => {
      const result = table.data.filter((row) => {
        const textHasBeenFound = searchTextInArray(search, [
          row.firstName,
          row.lastName,
          row.company,
        ]);

        return textHasBeenFound;
      });
      return !!result.length;
    });
  }, [search, tables]);

  const getTablePosition = (table: TableDataItem) => {
    if (!search.trim().length) {
      return;
    }
    return tables.findIndex((item) => item.id === table.id);
  };

  return (
    <Stack spacing={1.5} ref={tableContainerRef}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        pr={3}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <Button size={"large"} variant={"text"} onClick={onLeave}>
            <Iconify
              icon={"maki:arrow"}
              fontSize={35}
              sx={{ transform: "scale(-1, 1)" }}
            />
          </Button>
          <TextField
            size={"small"}
            label={"Rechercher"}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={"eva:search-outline"} />
                </InputAdornment>
              ),
            }}
          />
          <button
            className="button-Backoffice"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => setShowModal(true)}
          >
            Nouveau
          </button>
        </Stack>
        <Button variant={"contained"} size={"small"} onClick={exportMethod}>
          Export
        </Button>
      </Stack>

      <Stack direction={"row"} justifyContent={"space-between"} pr={3}>
        <Typography variant={"h5"} fontWeight={"normal"} mb={0}>
          Total de placé : <strong>{placedCount}</strong>
        </Typography>

        <Typography variant={"h5"} fontWeight={"normal"} mb={0}>
          Nombre de personne : <strong>{unplacedCount}</strong>
        </Typography>
      </Stack>

      <Divider />

      <Stack direction={"row"} flexWrap={"wrap"} sx={{ position: "relative" }}>
        {filteredTables.map((table, index) => (
          <TableItem
            key={table.id.toString()}
            id={table.id.toString()}
            data={table.data}
            name={table.name}
            tableContainerWidth={tableContainerWidth}
            index={index}
            position={getTablePosition(table) ?? index}
            onMove={(fromIndex, toIndex) => {
              console.log("moving");
              const to = tables[toIndex];
              const from = tables[fromIndex];

              if (Math.abs(fromIndex - toIndex) === 1) {
                tables[toIndex] = from;
                tables[fromIndex] = to;
              } else if (fromIndex > toIndex) {
                for (let i = fromIndex; i >= toIndex + 1; i--) {
                  tables[i] = tables[i - 1];
                }
                tables[toIndex] = from;
              } else if (toIndex > fromIndex) {
                for (let i = fromIndex; i <= toIndex - 1; i++) {
                  tables[i] = tables[i + 1];
                }
                tables[toIndex] = from;
              }

              onChange([...tables]);
            }}
            onAddItem={({ id }) => {
              onDrop(id, table);
            }}
            onDelete={() => {
              onDeleteTable(table);
            }}
            onEditName={onEditName}
            onDeleteUser={onDeleteUser}
            onAcceptUser={onAcceptUser}
            search={search}
          />
        ))}

        {!search.trim().length && (
          <Box width={tableContainerWidth / 3.4} mr={3} pb={3}>
            <Card
              onClick={() => {
                onChange([
                  ...tables,
                  {
                    data: [],
                    name: "",
                    id: String(
                      Math.max(...tables.map((t) => Number(t.id))) + 1
                    ),
                  },
                ]);
              }}
              sx={{
                height: TABLE_ITEM_HEIGHT,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "grab",
              }}
            >
              <Iconify
                icon={"eva:plus-outline"}
                fontSize={100}
                color={"GrayText"}
              />
            </Card>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
