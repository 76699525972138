import Header from "./HeaderPage.js";
import TitleArrow from "./TitlewithArrow.js";

import TitlePages from "./BigTitle.js";
import "./AccesFR.css";
import Footer from "./Footer.js";
import planAccesPdf from "./img/plans-pdf.pdf";

export default function AccesPage() {
  return (
    <div>
      <Header></Header>
      <Acces></Acces>
      <Footer></Footer>
    </div>
  );
}

function Acces() {
  return (
    <div>
      <TitlePages value="accès au Carrousel du Louvre"></TitlePages>

      <div className="div-map">
        <div className="adress">
          <p className="bold">
            Adresse :{" "}
            <span className="light">99, rue de Rivoli - 75001 Paris</span>{" "}
          </p>
        </div>

        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.7615561896055!2d2.332257475199068!3d48.86275710040093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e671fe085c22e9%3A0xf4ef51f9f726df26!2sCarrousel%20du%20Louvre!5e0!3m2!1sfr!2sfr!4v1687273448716!5m2!1sfr!2sfr"
            width="450"
            height="350"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

        <div className="adress">
          <p className="light">
            Télécharger le plan d'accès :{" "}
            <a
              href={planAccesPdf}
              target="_blank"
              rel="noopener noreferrer"
              className="yellow"
            >
              lien du PDF
            </a>{" "}
          </p>
        </div>
      </div>

      <div className="acces-paragraph">
        <div className="div-title-arrow">
          <TitleArrow value="En transports en commun"></TitleArrow>
        </div>
        <p className="bold">Bus</p>
        <p className="light">
          Lignes 21, 27, 39, 48, 67, 68, 69, 72, 81, 95 Arrêts Palais Royal -
          Musée du Louvre ou Palais Royal -<br></br> Comédie Française ou Musée
          du Louvre
        </p>
        <p className="bold">Métro</p>
        <p className="light">
          Lignes 1 et 7, Station Palais Royal - Musée du Louvre - sortie 6
        </p>

        <div className="div-title-arrow">
          <TitleArrow value="En Avion"></TitleArrow>
        </div>
        <p className="bold">
          Depuis l'aéroport Roissy-Charles de Gaulle{" "}
          <span>
            - 45 minutes en taxi <br /> RER B direction Saint-Rémy-lès-Chevreuse
            jusqu’à Châtelet - Les Halles, métro ligne 1 direction La Défense
            jusqu'à Palais Royal - Musée du Louvre
          </span>
        </p>

        <div className="div-title-arrow">
          <TitleArrow value="En taxi"></TitleArrow>
        </div>
        <div className="taxis">
          <p className="light">
            G7 <br />
            Taxis bleus <br />
            Alpha taxis <br />
          </p>
          <p className="light">
            3607 <br />
            3609 <br />
            01 45 85 85 85{" "}
          </p>
        </div>

        <div className="div-title-arrow">
          <TitleArrow value="En voiture"></TitleArrow>
        </div>
        <p className="bold">
          Le « Parc Carrousel Louvre »
          <span>
            {""} est accessible par l’avenue du Général Lemonnier, 75001 Paris{" "}
            <br />
            <br />
            · Depuis la rue de Rivoli : à gauche au feu de la place des
            Pyramides <br />· Depuis le quai des Tuileries : à gauche au feu du
            Pont-Royal <br />
            · Depuis le Pont-Royal : tout droit, direction "Opéra", puis serrer
            à droite <br /> <br />
            Dans les trois cas : s’engager dans le tunnel ; au feu situé au
            point le plus bas du tunnel, s’engager dans la rampe centrale
            indiquée « Parc Carrousel Louvre »
          </span>{" "}
        </p>
        <p className="light">
          Parking du Carrousel
          <br />
          1, avenue du Général Lemonnier
          <br />
          75001 Paris
          <br />
          <br />
          <span className="bold">Autres parkings à proximité :</span>
          <br />
          <br />
          Parking Pyramides
          <br />
          15, rue des Pyramides
          <br />
          75001 Paris
          <br />
          <br />
          Parking Saint-Honoré
          <br />
          39, place du Marché Saint-Honoré
          <br />
          75001 Paris
          <br />
        </p>
      </div>
    </div>
  );
}
