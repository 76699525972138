import "../Form-tout.css";
import Header from "./HeaderPage.js";
import "../HeaderFR.css";
import programmeTout from "../img/programme-tout-EN.png";
import programmeToutMobile from "../img/programme-tout-mobile-EN.png";
import TitleArrow from "../TitlewithArrow";
import { useForm } from "react-hook-form";
import checkLine from "../img/check-line.png";
import { useState } from "react";
import api from "../api";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import getErrorDic from "../getErrorDic";
import swal, { redirectSwalEN } from "../swal";
import Footer from "./Footer.js";
const getErrorMessage = getErrorDic("en");

export default function FormInscription() {
  return (
    <div>
      <Header />

      <div className="body">
         <Programme></Programme>
        {/* <Form></Form>  */}
        <EndRegistrationEn></EndRegistrationEn>
      </div>
      <Footer></Footer>
    </div>
  );
}

function Programme() {
  return (
    <div className="programme">
      <img className="programme-long" src={programmeTout}></img>
      <img className="programme-long-mobile" src={programmeToutMobile}></img>
    </div>
  );
}

function EndRegistrationEn() {
  return(
    <div className="title-form">
      <div>
        <div className="title">
          <h1 className="title-h1">Unigrains 60th anniversary celebration</h1>
          <h2 className="title-h2">
            Thursday, October 5th, 2023 at les Salles du Carrousel du Louvre,
            Paris
          </h2>

          <p className="title-p">
            We look forward to seeing you to celebrate our 60 years of shared
            values and to reflect together on the key challenges of our
            agri-food sectors!
          </p>

          <p className="title-p-bold">Registration for Unigrains 60th Anniversary Celebration is now closed. </p>
         
          <p className="title-p"> For any further information or to modify your inscription, please contact us by mail at :
          <a className="title-a" href="mailto:contact@unigrains.fr"> contact@unigrains.fr </a></p>
          
          
          </div>
          </div>
          </div>
  )
}


function Form() {
  return (
    <div className="title-form">
      <div>
        <div className="title">
          <h1 className="title-h1">Unigrains 60th anniversary celebration</h1>
          <h2 className="title-h2">
            Thursday, October 5th, 2023 at the Carrousel du Louvre, Paris
          </h2>

          <p className="title-p">
            We look forward to seeing you to celebrate our 60 years of shared
            values and to reflect together on the key challenges of our
            agri-food sectors!
          </p>
          <h3 className="title-h3">Please confirm your participation</h3>
        </div>
      </div>
      <div className="div-form-infos">
        <FormInfos></FormInfos>
      </div>
    </div>
  );
}

function FormInfos() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [participateCocktail, setParticipateCocktail] = useState();
  const [participateTour, setParticipateTour] = useState();
  const [participateConference, setParticipateConference] = useState();
  const [participateDiner, setParticipateDiner] = useState();
  const [foodSpecificity, setFoodSpecificity] = useState();
  const [isLoading, setIsLoading] = useState();
  const [participate, setParticipate] = useState();

  const checkEmailForm = async (email) => {
    try {
      const res = await api.get("/checkMailForm", {
        params: { email },
      });

      if (res.data.isNightOnly) {
        redirectSwalEN({
          text: "You are on the wrong page.",
          url: "/en/celebration",
        });
      }
    } catch (e) {
      if (e === "emailNotFound") {
        swal(getErrorMessage(e));
      }
    }
  };

  const onSubmit = async (data) => {
    if (participate === false) {
      navigate("/en/60/confirmation-later");
    } else if (participate === true) {
      try {
        setIsLoading(true);
        await api.post("/inscription", {
          ...data,
          participateCocktail,
          participateTour,
          participateConference,
          participateDiner,
          foodSpecificity,
        });
        navigate("/en/60/confirmation");
      } catch (e) {
        swal(getErrorMessage(e));
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-info">
      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">I will participate</p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Yes"
            inputValue={true}
            value={participate}
            setValue={setParticipate}
          />
          <CustomCheckBox
            label="I’m not sure yet"
            inputValue={false}
            value={participate}
            setValue={setParticipate}
          />
        </div>
      </div>
      {participate ? (
        <>
          <div className="div-input-double">
            <InputForm
              inputName={"firstName"}
              register={register}
              inputWidth="50%"
              label="First name"
            />
            <InputForm
              inputName={"lastName"}
              register={register}
              inputWidth="50%"
              label="Last name"
            />
          </div>
          <div>
            <InputForm
              inputName={"email"}
              register={register}
              label="E-mail"
              bottomText={
                "Enter the email on which you received the invitation"
              }
              onBlur={checkEmailForm}
            />
            <InputForm
              inputName={"company"}
              register={register}
              label="Company"
            />
          </div>

          <CheckboxForm
            participateCocktail={participateCocktail}
            participateTour={participateTour}
            participateConference={participateConference}
            participateDiner={participateDiner}
            foodSpecificity={foodSpecificity}
            setParticipateCocktail={setParticipateCocktail}
            setParticipateTour={setParticipateTour}
            setParticipateConference={setParticipateConference}
            setParticipateDiner={setParticipateDiner}
            setFoodSpecificity={setFoodSpecificity}
            register={register}
          ></CheckboxForm>
        </>
      ) : (
        ""
      )}

      <div className="div-btn">
        <button disabled={isLoading} className="btn-end-form">
          Confirm
          {isLoading ? (
            <TailSpin
              height="18"
              width="18"
              color="#000000"
              radius="0"
              wrapperStyle={{
                display: "block",
                margin: "0 auto",
                borderColor: "red",
              }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            ""
          )}
        </button>
      </div>
    </form>
  );
}

function CheckboxForm({
  register,
  participateCocktail,
  participateTour,
  participateConference,
  participateDiner,
  foodSpecificity,
  setParticipateCocktail,
  setParticipateTour,
  setParticipateConference,
  setParticipateDiner,
  setFoodSpecificity,
}) {
  return (
    <div>
      <Cocktail
        setParticipateCocktail={setParticipateCocktail}
        participateCocktail={participateCocktail}
      ></Cocktail>
      <Louvre
        participateTour={participateTour}
        setParticipateTour={setParticipateTour}
      ></Louvre>
      <Celebration
        participateConference={participateConference}
        participateDiner={participateDiner}
        setParticipateConference={setParticipateConference}
        setParticipateDiner={setParticipateDiner}
      ></Celebration>
      <Food
        foodSpecificity={foodSpecificity}
        setFoodSpecificity={setFoodSpecificity}
        register={register}
      ></Food>
      <EndForm register={register}></EndForm>
    </div>
  );
}

function Cocktail({ participateCocktail, setParticipateCocktail }) {
  return (
    <div>
      <TitleArrow value="12PM to 4PM: Strategic exchanges - “Agri-food companies in the age of transitions”"></TitleArrow>
      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">I will participate</p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Yes"
            inputValue={true}
            value={participateCocktail}
            setValue={setParticipateCocktail}
          />
          <div className="align-checkbox">
            <CustomCheckBox
              label="No"
              inputValue={false}
              value={participateCocktail}
              setValue={setParticipateCocktail}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Louvre({ participateTour, setParticipateTour }) {
  return (
    <div>
      <TitleArrow
        value={`4PM to 6PM: Guided tour of the Louvre - “Grains in Art”`}
      ></TitleArrow>
      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">I will participate</p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Yes"
            inputValue={true}
            value={participateTour}
            setValue={setParticipateTour}
          />
          <div className="align-checkbox">
            <CustomCheckBox
              label="No"
              inputValue={false}
              value={participateTour}
              setValue={setParticipateTour}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Celebration({
  participateConference,
  setParticipateConference,
  participateDiner,
  setParticipateDiner,
}) {
  return (
    <div>
      <TitleArrow value="6PM to midnight: Unigrains 60th anniversary celebration"></TitleArrow>
      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">Conference</p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Yes"
            inputValue={true}
            value={participateConference}
            setValue={setParticipateConference}
          />
          <div className="align-checkbox">
            <CustomCheckBox
              label="No"
              inputValue={false}
              value={participateConference}
              setValue={setParticipateConference}
            />
          </div>
        </div>
      </div>
      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">Seated diner & private concert</p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Yes"
            inputValue={true}
            value={participateDiner}
            setValue={setParticipateDiner}
          />
          <div className="align-checkbox">
            <CustomCheckBox
              label="No"
              inputValue={false}
              value={participateDiner}
              setValue={setParticipateDiner}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Food({ register, foodSpecificity, setFoodSpecificity }) {
  return (
    <div>
      <TitleArrow value="Dietary restrictions"></TitleArrow>

      <div className="line-checkbox">
        <div className="p-checkbox-div">
          <p className="p-checkbox">Dietary restrictions? </p>
        </div>
        <div className="checkbox-div">
          <CustomCheckBox
            label="Yes"
            inputValue={true}
            value={foodSpecificity}
            setValue={setFoodSpecificity}
          />

          <div className="align-checkbox">
            <CustomCheckBox
              label="No"
              inputValue={false}
              value={foodSpecificity}
              setValue={setFoodSpecificity}
            />
          </div>
        </div>
      </div>
      {foodSpecificity ? (
        <div className="end-form">
          <label htmlFor="haveSpecialFood">
            Please indicate your dietary restrictions
          </label>
          <textarea
            className="input-end-form"
            {...register("haveSpecialFood")}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

function EndForm({ register }) {
  return (
    <div className="end-form">
      <label htmlFor="other">
        Do you have any other precisions or questions?
      </label>

      <textarea className="input-end-form" {...register("other")} />
    </div>
  );
}

function CustomCheckBox({ label, inputValue, value, setValue }) {
  const isChecked = inputValue === value;
  return (
    <div className="checkbox-wrapper" onClick={() => setValue(inputValue)}>
      <div className={isChecked ? "checkbox-checked" : "checkbox-unchecked"}>
        {isChecked ? (
          <img alt="" className="check-line" src={checkLine}></img>
        ) : (
          ""
        )}
      </div>
      <span>{label}</span>
    </div>
  );
}

function InputForm({
  label,
  inputWidth,
  inputName,
  register,
  onBlur,
  bottomText,
}) {
  return (
    <label style={{ width: inputWidth }} className="input-form">
      <span className="span-input">{label}</span>
      <input
        className="input-info"
        type="text"
        {...register(inputName)}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e.target.value);
          }
        }}
      ></input>
      {bottomText ? <span className="bottomText">{bottomText}</span> : ""}
    </label>
  );
}
