import Header from "./HeaderPage";
import TitlePages from "../BigTitle";
import "../Contact.css";
import Footer from "./Footer";

export default function ContactPage() {
  return (
    <div>
      <Header></Header>
      <TitlePages value="Contact"></TitlePages>

      <div className="div-contact">
        <p>
          Please do not hesitate to contact us for any further information:
          <br />
          <br />
          <br />
          +33 (0)6 69 20 79 19 <br />
          <br />
          or by e-mail:
          <br />
          <br />
          <a href="mailto:contact@unigrains.fr">contact@unigrains.fr</a>
        </p>
        <br />
        <br />
      </div>
      <Footer></Footer>
    </div>
  );
}
