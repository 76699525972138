import "../Cgu.css";
import Header from "./HeaderPage";
import TitlePages from "../BigTitle";
import TitleArrow from "../TitlewithArrow";
import Footer from "./Footer.js";

export default function CguPage() {
  return (
    <div>
      <Header></Header>

      <TitlePages value="Terms of Service"></TitlePages>
      <div className="div-cgu">
        <p className="light">
          The Unigrains 60 website is a site published independently by ARKONE
          SAS, with a capital of 5 000 euros, whose head office is located at
          105 rue de Longchamp, 92200 Neuilly-Sur-Seine, France, registered in
          the Paris Trade and Companies Register under the number 892975368.
          <br /> <br /> The purpose of these General Terms and Conditions of Use
          (hereinafter “GTC”) is to set the rules for the use of the Unigrains
          60th anniversary site by users. Use of the site implies acceptance by
          the user of these T&Cs. By accessing it, you accept all of these T&Cs.
          For any question relating to the T&Cs or the use of the website, you
          can write to us at the following address: arkone-france@outlook.fr{" "}
          <br /> <br /> The Publisher is free to modify the T&Cs at any time.
          The User is therefore advised to regularly refer to the latest version
          of the T&Cs.
        </p>
      </div>
      <div className="div-cgu">
        <TitleArrow value="DEFINITIONS"></TitleArrow>
        <p className="light">As designated:</p>
        <p className="light">
          “Site”: the Unigrains 60th anniversary site and all of its pages and
          screens. <br /> <br />
          “Database”: the event information database used on the Site. <br />{" "}
          <br />
          “Editor”: the legal entity responsible for the editing and content of
          the Site.
          <br /> <br />
          “User”: the person using the Site. <br /> <br />« “Partners”: the
          companies: Franco American Image with a capital of 99,170 euros, whose
          registered office is located at 99 rue de la République, 92800
          Puteaux, France registered in the Nanterre Trade and Companies
          Register under number 411 032 105 and UNIGRAINS, a public limited
          company with share capital of €7,000,000, whose registered office is
          located at 23 avenue de Neuilly, 75116 Paris France, registered with
          the Paris Trade and Companies Register under number 642 008 296,
          responsible for the event for which we act as a subcontractor.
        </p>
      </div>
      <div className="div-cgu">
        <TitleArrow value="PURPOSE"></TitleArrow>
        <p className="light">
          The purpose of the site is to present and allow registration at
          Unigrains 60th anniversary only to invited persons.
        </p>
      </div>
      <div className="div-cgu">
        <TitleArrow value="TERMS OF USE"></TitleArrow>
        <h2 className="h2-cgu">3.1 Access to the Website</h2>
        <p className="light">
          The site is accessible to all but validation of the form is restricted
          to people who have been invited and whose email is registered in the
          database provided by Unigrains.
        </p>
        <p className="light">
          Once the registration has been completed, it is no longer possible to
          modify its information.
        </p>
        <p className="light">
          The User is responsible for taking all the necessary measures to
          protect their data against any attack. In the event of technical
          problems or for maintenance reasons, accessibility to the Site may be
          disrupted and/or suspended.
          <br />
          The Publisher will use the contact details provided by the User and
          its Partners when registering for a personal account. Failing this,
          the Publisher will not be able to send him the various information and
          alerts provided for in the context of these Conditions and cannot be
          held responsible for them.
          <br />
          Subject to any breakdowns and maintenance interventions necessary for
          the proper functioning of the Platform as well as cases of force
          majeure, the Publisher endeavors to allow access to the Site 24 hours
          a day, 7 days a week.
          <br />
        </p>
        <p className="light">
          However, the Publisher cannot guarantee the availability of the Site
          and services and reserves the right to temporarily or permanently
          interrupt access to all or part of the Site or all or part of the
          services, at any time, without notice.
        </p>
        <p className="light">
          The Publisher cannot be held liable for these facts. In the event of
          interruption, the User acknowledges and accepts that the Publisher is
          not responsible for the consequences which may result therefrom for
          himself or any third party.
        </p>
        <h2 className="h2-cgu">Intellectual property</h2>
        <p className="light">
          The exclusive use of trademarks, logos, software, graphic elements,
          databases, and more generally of all content offered by the
          Application are the property of the Publisher, or its Partners and are
          therefore protected by copyright and trademark law.
        </p>
        <h2 className="h2-cgu">Principles of security</h2>
        <p className="light">
          The Publisher reserves the right to suspend the User's access in the
          event of a risk concerning the security of the computer system or
          presumption of unauthorized or fraudulent use of the service. <br />
          The User is entirely responsible for the use, conservation and
          confidentiality of the site, and undertakes to inform the Publisher of
          any suspicion of fraudulent use of his Online Space.
        </p>
        <h2 className="h2-cgu">Rights and Obligations of the Publisher</h2>
        <p className="light">
          The User expressly admits using the Site at his own risk and under his
          exclusive responsibility.
        </p>
        <h2 className="h2-cgu">Others</h2>
        <p className="light">
          The Publisher assumes no responsibility for any damage caused in the
          event of fraudulent use of the Site, in the event of communication of
          inaccurate or incomplete information by the User when using remote
          services.
        </p>
        <p className="light">
          The User is solely responsible for additional or incidental costs
          resulting from his use of the Platform, including in particular any
          cost of communication, acquisition of software and/or equipment,
          repair or storage of data, rental or use of a license, etc. Under no
          circumstances may the payment of such costs be requested from the
          Publisher or constitute a compensable prejudice.
        </p>
        <p className="light">
          Similarly, the Publisher cannot be held liable if the use of these
          sites by the User causes harm.
        </p>
      </div>
      <div className="div-cgu">
        <TitleArrow value="DATA COLLECTION"></TitleArrow>
        <p className="light">
          In accordance with the General Data Protection Regulations (GDPR), the
          collection and processing of data respects the following principles:
          Lawfulness, loyalty and transparency, limited purposes, storage of
          data reduced over time, integrity and confidentiality of data
          collected and processed.
        </p>
        <p className="light">
          The User may use his right of access, rectification, erasure and
          portability of relevant data and may also, for legitimate reasons,
          oppose the processing and, in the cases provided for by law, request
          limitation of the processing of this data.
        </p>
        <h2 className="h2-cgu">Data collection systems</h2>
        <h3 className="h3-cgu">Website</h3>
        <p className="light">
          When creating an account on the website, the following data is
          collected for the User:
        </p>
        <p className="light">
          Civility, surname, first name, email, company, list of participations,
          food specifics and related questions. This data is collected to allow
          the proper organization of the event.
        </p>
        <h2 className="h2-cgu">Data recipient</h2>
        <p className="light">
          The personal data strictly necessary for the accomplishment of their
          missions are transmitted to the authorized departments of the
          Publisher, namely the IT, legal and management departments.
        </p>
        <p className="light">
          In addition, they may be communicated to service providers acting as
          subcontractors.
        </p>
        <p className="light">
          Likewise, the data will be shared with our Partners for the proper
          management of the event.
        </p>
        <p className="light">
          The Publisher ensures compliance with data protection requirements for
          all its subcontracting companies. However, the Publisher remains free
          to choose its technical and commercial subcontractors on the condition
          that they present sufficient guarantees with regard to the
          requirements of the Regulations. Also, the Publisher is not
          responsible for compliance with data protection requirements when the
          data is transmitted to their Partners.
        </p>
        <p className="light">
          Finally, when required, the data may be communicated to the
          administrative or judicial authorities.
        </p>
        <h2 className="h2-cgu">Duration of data conservation</h2>
        <p>The data is kept until its purposes are achieved.</p>
        <h2 className="h2-cgu">User rights to data</h2>
        <p className="light">
          In accordance with the Regulations, the User has the following rights:
          <br />
          <br />
          Right of access to data
          <br />
          Right of rectification
          <br />
          Right of opposition
          <br />
          Right to deletion of data
          <br />
          Right to data portability
          <br />
          Right to restriction of processing
          <br />
          Right to withdraw consent
          <br /> <br />
          To exercise these rights, the User must contact the Publisher at the
          following email address: 
          <a href="mailto:arkone-france@outlook.fr">arkone-france@outlook.fr</a>
        </p>
      </div>

      <Footer></Footer>
    </div>
  );
}
