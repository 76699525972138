import Header from "./HeaderPage";
import TitlePages from "./BigTitle";
import TitleArrow from "./TitlewithArrow";
import imgEchange from "./img/img-echanges.png";
import imgVisite from "./img/img-visite.png";
import imgCelebration from "./img/img-celebration.png";
import visuel1 from "./img/programme/visuel-1.png";
import visuel2 from "./img/programme/visuel2.png";
import visuel3 from "./img/programme/visuel3.png";
import visuel4 from "./img/programme/visuel4.png";
import visuel5 from "./img/programme/visuel-5.png";
import visuel6 from "./img/programme/visuel-6.png";
import visuel7 from "./img/programme/visuel-7.png";
import visuel8 from "./img/programme/visuel-8.png";
import visuel9 from "./img/programme/visuel-9.png";
import portrait1 from "./img/programme/portrait-1.png";
import portrait2 from "./img/programme/portrait-2.png";
import portrait3 from "./img/programme/portrait-3.png";
import portrait4 from "./img/programme/portrait-4.png";
import portrait5 from "./img/programme/portrait-5.png";
import portrait6 from "./img/programme/portrait-6.png";
import portrait7 from "./img/programme/portrait-7.png";
import portrait8 from "./img/programme/portrait-8.png";
import portrait9 from "./img/programme/portrait-9.png";
import chef from "./img/programme/Chef.png";
import lej from "./img/programme/lej.png";
import points from "./img/programme/points.png";
import "./Programme.css";
import Footer from "./Footer.js";

export default function PageProgramme() {
  return (
    <div>
      <Header></Header>
      <TitlePages value="Programme détaillé"></TitlePages>

      <div className="body-programme">
        <div style={{ flex: 1 }}>
          <div className="line-programme">
            {/* <p className="title-bg-programme-top">COCKTAIL DÉJEUNATOIRE</p>
            <p className="title-bg-programme-bottom-small">& CONFÉRENCE</p> */}
            <TitleArrow value="12h à 13h30"></TitleArrow>
            <p className="h4" style={{ marginBottom: 30 }}>
              Cocktail déjeunatoire
            </p>
            <TitleArrow value="13h30"></TitleArrow>
            <p className="h4">Ouverture des portes de la Conférence : </p>
            <p className="h2" style={{ marginBottom: 30 }}>
              "L'entreprise agroalimentaire à l'heure des transitions"
            </p>
            <img className="img-programme-full" src={visuel1}></img>
            <img className="img-programme-intermediate" src={visuel2}></img>
            <img className="img-programme-intermediate" src={visuel3}></img>
            <img className="img-programme-intermediate" src={visuel4}></img>
            <p className="h4-clear" style={{ marginLeft: 80 }}>
              Avec les interventions de :
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "10%",
                marginLeft: "10%",
              }}
            >
              <div className="multiple-row-programme">
                <img className="img-programme-full" src={visuel5}></img>
                <p className="name-programme">JACQUES BOURGEAIS</p>
                <p className="function-programme">Directeur Général, CAVAC</p>
              </div>
              <div className="multiple-row-programme">
                <img className="img-programme-full" src={visuel6}></img>
                <p className="name-programme">DIDIER BOUDY</p>
                <p className="function-programme">
                  Président, Mademoiselle Desserts
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "10%",
                marginLeft: "10%",
              }}
            >
              <div className="multiple-row-programme">
                <img className="img-programme-full" src={visuel9}></img>
                <p className="name-programme">LAËTITIA DESVIGNES</p>
                <p className="function-programme">
                  Directrice Mission et Impacts, Restoria
                </p>
              </div>
              <div className="multiple-row-programme">
                <img className="img-programme-full" src={visuel7}></img>
                <p className="name-programme">YVAN SCHAEPMAN</p>
                <p className="function-programme">
                  Directeur Général, Boortmalt
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "10%",
              marginLeft: "10%",
              marginTop: 20,
            }}
          ></div>
        </div>
        <div style={{ flex: 1 }}>
          <div className="line-programme">
            <TitleArrow value="16h à 17h30"></TitleArrow>
            <p className="h2">Visite guidée du Louvre</p>
            <p className="h4" style={{ marginBottom: 70 }}>
              Parcours exceptionnel :<br />« Les Céréales dans l’Art »
            </p>
            <TitleArrow value="17h30"></TitleArrow>
            <p className="h4">
              Ouverture des portes de la Célébration des 60 ans
            </p>
            <p className="h2" style={{ marginBottom: 30 }}>
              «Unigrains aujourd’hui et demain »
            </p>
            <img className="img-programme-full" src={visuel8}></img>
            <p className="h4-clear">Avec les interventions de :</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img className="img-programme-full" src={portrait1}></img>
                <p className="name-programme">ERIC THIROUIN</p>
                <p className="function-programme">
                  Président-Directeur Général, Unigrains
                </p>
              </div>

              <div>
                <img className="img-programme-full" src={portrait2}></img>
                <p className="name-programme">MAXIME VANDONI</p>
                <p className="function-programme">
                  Directeur Général Délégué, Unigrains
                </p>
              </div>

              <div>
                <img className="img-programme-full" src={portrait3}></img>
                <p className="name-programme">JEAN-FRANÇOIS ISAMBERT</p>
                <p className="function-programme">Vice-Président, Unigrains</p>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img className="img-programme-full" src={portrait4}></img>
                <p className="name-programme">RAPHAËL LATZ</p>
                <p className="function-programme">
                  Directeur, Louis Dreyfus Company
                </p>
              </div>
              <div>
                <img className="img-programme-full" src={portrait5}></img>
                <p className="name-programme">PASCAL LHEUREUX</p>
                <p className="function-programme">
                  Président de la Caisse régionale de Normandie, Crédit Agricole
                </p>
              </div>
              <div>
                <img className="img-programme-full" src={portrait9}></img>
                <p className="name-programme">JÉRÔME CALLEAU</p>
                <p className="function-programme">Président, InVivo</p>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 80,
                }}
              >
                <div>
                  <img className="img-programme-full" src={portrait6}></img>
                  <p className="name-programme">STANISLAS LEMOR</p>
                  <p className="function-programme">
                    Président-Directeur-Général, STEF
                  </p>
                </div>
                <div>
                  <img className="img-programme-full" src={portrait7}></img>
                  <p className="name-programme">OLIVIER SERGENT</p>
                  <p className="function-programme">
                    Président, TMG et Mecatherm
                  </p>
                </div>
                <div>
                  <img className="img-programme-full" src={portrait8}></img>
                  <p className="name-programme">PIERRE-EMMANUEL TAITTINGER</p>
                  <p className="function-programme">Taittinger</p>
                </div>
              </div>
            </div>
            <TitleArrow value="20h à 23h30"></TitleArrow>
            <p className="title-bg-programme-top" style={{ marginTop: 10 }}>
              COCKTAIL AUTOUR
            </p>
            <p className="title-bg-programme-bottom">
              DES SAVOIR-FAIRE DE NOS RÉGIONS
            </p>
            <p className="title-bg-programme-top">
              SOIRÉE "CÉLÉBRATION DES 60 ANS"
            </p>
            <li className="li-programme" style={{ marginTop: 15 }}>
              Dîner placé dans la salle Le Nôtre
            </li>
            <li className="li-programme">
              Menu signature imaginé par un grand chef
            </li>

            <img
              className="img-programme-intermediate"
              src={chef}
              style={{ marginBottom: 40, marginTop: 15 }}
            ></img>
            <li className="li-programme">
              Concert privé inédit du groupe LEJ « 60 ans de chansons »
            </li>

            <img
              className="img-programme-intermediate"
              src={lej}
              style={{ marginBottom: 50, marginTop: 15 }}
            ></img>
            <TitleArrow value="23h30 à 01h"></TitleArrow>
            <p className="title-bg-programme-top">
              FIN DE SOIRÉE AMBIANCE LOUNGE
            </p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
