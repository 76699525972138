const errorMessages = {
  en: {
    emailNotFound: "We cannot find your e-mail in the database",
    wrongForm: "You are on the wrong form",
    missingFirstName: "First Name is missing",
    missingLastName: "Last Name is missing",
    missingCompany: "Company is missing",
    missingEmail: "E-mail is missing",
    invalidEmail: "Invalid e-mail",
    default: "An error has occurred",
  },
  fr: {
    emailNotFound: "Nous ne retrouvons pas votre email dans la base de données",
    wrongForm: "Vous n'êtes pas sur le bon formulaire",
    missingFirstName: "Le champ Prénom est manquant",
    missingLastName: "Le champ Nom est manquant",
    missingCompany: "Le champ Entreprise est manquant",
    missingEmail: "Le champ Email est manquant",
    invalidEmail: "Email invalide",
    default: "Une erreur s'est produite",
    missingPassword: "Mot de passe est manquant",
    invalidCredentials: "Identifiants non valide",
  },
};

const getErrorDic = (lang) => {
  const messages = errorMessages[lang || "fr"];
  return (code) => {
    if (!Object.keys(messages).includes(code)) {
      code = "default";
    }
    return messages[code];
  };
};

export default getErrorDic;
