import Header from "./HeaderPage";
import TitlePages from "../BigTitle";
import "../HotelFR.css";
import TitleArrow from "../TitlewithArrow";
import timhotelDuLouvre from "../img/timhotel-du-louvre.png";
import hotelBonsEnfants from "../img/hotel-bons-enfants.png";
import hotelCrayon from "../img/hotel-crayon.png";
import hotelSaintHonore from "../img/hotel-saint-honore.png";
import hotelBoutique from "../img/hotel-boutique.png";
import empireParis from "../img/empire-paris.png";
import hotelPalaisRoyale from "../img/hotel-palais-royal.png";
import hotelHyatt from "../img/hotel-hyatt.png";
import hotelPontNeuf from "../img/hotel-pont-neuf.png";
import Footer from "./Footer";

export default function HotelsPage() {
  return (
    <div>
      <Header></Header>

      <TitlePages value="Nearby hotels"></TitlePages>
      <div className="title-page">
        <p className="light">
          Find below a selection of hotels located less than 600 meters from the
          Carrousel du Louvre
        </p>
      </div>

      <div className="hotel-line-and-title">
        <TitleArrow value="5 star hotels"></TitleArrow>
        <div className="hotel-line">
          <HotelBloc
            imgName={hotelPalaisRoyale}
            hotelName="Grand Hôtel Du Palais Royal"
            hotelAdress="4, rue De Valois - 75001 Paris"
            hotelLink="https://www.grandhoteldupalaisroyal.com/"
          ></HotelBloc>
          <HotelBloc
            imgName={hotelHyatt}
            hotelName="Hôtel du Louvre, in The Unbound Collection by Hyatt"
            hotelAdress="Place André Malraux - 75001 Paris"
            hotelLink="https://www.hyatt.com/fr-FR/hotel/france/hotel-du-louvre/paraz"
          ></HotelBloc>
          <HotelBloc
            imgName={hotelPontNeuf}
            hotelName="Maison Albar Hotels Le Pont-Neuf"
            hotelAdress="23-25 rue de Pont Neuf - 75001 Paris"
            hotelLink="https://www.maison-albar-hotels-le-pont-neuf.com/"
          ></HotelBloc>
        </div>
      </div>

      <div className="hotel-line-and-title">
        <TitleArrow value="4 star hotels"></TitleArrow>
        <div className="hotel-line">
          <HotelBloc
            imgName={hotelSaintHonore}
            hotelName="Hotel Louvre Saint-Honoré"
            hotelAdress="141, rue St Honoré - 75001 Paris"
            hotelLink="https://www.hotel-louvre-saint-honore.com/"
          ></HotelBloc>
          <HotelBloc
            imgName={hotelBoutique}
            hotelName="Boutique Hôtel Konfidentiel"
            hotelAdress="64, rue de l'Arbre Sec - 75001 Paris"
            hotelLink="https://www.konfidentiel-paris.com/"
          ></HotelBloc>
          <HotelBloc
            imgName={empireParis}
            hotelName="L'Empire Paris"
            hotelAdress="48, rue De L'arbre Sec - 75001 Paris"
            hotelLink="https://www.lempire-paris.com/"
          ></HotelBloc>
        </div>
      </div>

      <div className="hotel-line-and-title">
        <TitleArrow value="3 star hotels"></TitleArrow>
        <div className="hotel-line">
          <HotelBloc
            imgName={timhotelDuLouvre}
            hotelName="Timhotel Le Louvre"
            hotelAdress="4, rue Croix des Petits Champs - 75001 Paris"
            hotelLink="https://www.timhotel.com/fr/timhotel-le-louvre-3_8"
          ></HotelBloc>
          <HotelBloc
            imgName={hotelBonsEnfants}
            hotelName="Hôtel Louvre Bons Enfants"
            hotelAdress="5, rue des Bons Enfants - 75001 Paris"
            hotelLink="https://www.hotellouvrebonsenfants.com/"
          ></HotelBloc>
          <HotelBloc
            imgName={hotelCrayon}
            hotelName="Hôtel Crayon by Elegancia"
            hotelAdress="25, rue du Bouloi - 75001 Paris"
            hotelLink="https://hotelcrayon.com/fr/"
          ></HotelBloc>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

function HotelBloc(props) {
  return (
    <div className="hotel-bloc">
      <img className="img-hotel" src={props.imgName}></img>
      <p className="plight">{props.hotelName} </p>
      <p className="plight-small">{props.hotelAdress}</p>
      <a href={props.hotelLink} target="_blank" className="alight">
        {props.hotelLink}{" "}
      </a>
    </div>
  );
}
