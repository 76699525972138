import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import "../Footer.css";

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const parts = currentPath.split("/");
  let basePath;
  if (["60", "celebration"].includes(parts[2])) {
    basePath = "/en/" + parts[2];
  } else {
    basePath = "/en/60";
  }
  return (
    <div className="footer">
      <button
        onClick={() => {
          navigate(`${basePath}/cgu`);
        }}
        className="link-footer"
      >
        Terms of Service
      </button>
      <button
        onClick={() => {
          navigate(`${basePath}/mentions-legales`);
        }}
        className="link-footer"
      >
        Legal notice
      </button>
    </div>
  );
}
