import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { TailSpin } from "react-loader-spinner";
import api from "./api";
import getErrorDic from "./getErrorDic";
import swal from "./swal";
import useAuthStore from "./auth/useAuthStore";
const jatLogo = require("./img/logo.png");

const getErrorMessage = getErrorDic("fr");

export const ButtonOrange = (props) => {
  const { buttonText, onClick, isLoading } = props;
  return (
    <button
      disabled={isLoading}
      onClick={onClick}
      className="button-orange"
      style={props.style}
    >
      {isLoading ? (
        <TailSpin
          height="18"
          width="18"
          color="#000000"
          radius="0"
          wrapperStyle={{
            display: "block",
            margin: "0 auto",
            borderColor: "red",
          }}
          wrapperClass=""
          visible={true}
        />
      ) : (
        buttonText
      )}
    </button>
  );
};

export default function PageLogIn() {
  return (
    <div>
      <LogIn></LogIn>
    </div>
  );
}

function LogIn() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const login = useAuthStore((state) => state.login);
  const loginClick = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const res = await api.get("/login", {
        params: { email, password },
      });
      login(res.data);
      navigate("/back-uni-office");
    } catch (e) {
      swal(getErrorMessage(e));
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bloc-jaune">
      <div className="connexion">
        <form>
          <img src={jatLogo} alt="jatLogo" className="jat-logo"></img>

          <h2 style={{ textDecoration: "none" }}> Connexion</h2>
          <div>
            <label className="label-login">
              Email
              <input
                className="input-30"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </label>
          </div>

          <div style={{ marginTop: "20px" }}>
            <label className="label-login">
              Password
              <input
                className="input-30"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </label>
          </div>
          <div className="input-connexion">
            <ButtonOrange
              isLoading={isLoading}
              onClick={loginClick}
              buttonText="Connexion"
            >
              Connexion
            </ButtonOrange>
          </div>
        </form>
      </div>
    </div>
  );
}
