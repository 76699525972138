import React, { useEffect, useMemo, useState } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import "./Backoffice.css";
import { FaPaperPlane, FaCheck, FaTimes, FaCocktail } from "react-icons/fa";
import {
  FaPenToSquare,
  FaDeleteLeft,
  FaBellConcierge,
  FaRegBell,
  FaFilePdf,
} from "react-icons/fa6";
import { useForm } from "react-hook-form";
import api from "./api";
import errorSwal, {
  confirmationSwal,
  editEmailSwal,
  longLoadingSwal,
  successSwal,
} from "./swal";
import logo from "./img/logo.png";
import ExcelJS from "exceljs";
import moment from "moment";
import Modal from "react-modal";
import { TailSpin } from "react-loader-spinner";
import { GiLouvrePyramid, GiMicrophone } from "react-icons/gi";
import ReactDOM from "react-dom";
import { VictoryPie } from "victory";

// import { Doughnut } from 'react-chartjs-2';

const CheckOrCross = ({ value }) => {
  if (value === undefined) return null;

  return value ? (
    <FaCheck className="text-green-600" />
  ) : (
    <FaTimes className="text-red-600" />
  );
};
const ContactCard = ({
  contact,
  editParticipantEmail,
  deleteParticipant,
  setModifyIsOpen,
  setParticipantData,
}) => {
  return (
    <tr className="transition-colors duration-200 hover:bg-gray-200">
      <td className="px-2 py-4">
        <div className="text-sm font-medium text-gray-900">
          {contact.lastName}
        </div>
      </td>
      <td className="px-2 py-4">
        <div className="text-sm font-medium text-gray-900">
          {contact.firstName}
        </div>
      </td>
      <td className="px-2 py-4">
        <div className="text-sm text-gray-900">{contact.email}</div>
      </td>
      <td className="px-2 py-4">
        <div className="text-sm text-gray-900">{contact.companyImport}</div>
      </td>
      <td className="px-2 py-4">
        <div className="text-sm text-gray-900">
          {contact.isFrench ? "FR" : "EN"}
        </div>
      </td>
      <td className="px-2 py-4">
        <div className="text-sm text-gray-900">
          {contact.isNightOnly ? "Soirée" : "Tout"}
        </div>
      </td>
      <td className="px-2 py-4">
        <CheckOrCross value={contact.hasAnswered} />
      </td>
      <td className="px-2 py-4">
        <CheckOrCross value={contact.participateCocktail} />
      </td>
      <td className="px-2 py-4">
        <CheckOrCross value={contact.participateTour} />
      </td>
      <td className="px-2 py-4">
        <CheckOrCross value={contact.participateConference} />
      </td>
      <td className="px-2 py-4">
        <CheckOrCross value={contact.participateDiner} />
      </td>
      <td className="px-2 py-4">
        <div className="text-sm text-gray-900">
          {contact?.updatedAt
            ? moment(contact?.updatedAt).format("YYYY-MM-DD hh:mm")
            : ""}
        </div>
      </td>
      <td className="px-2 py-4 text-right text-sm font-medium">
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2"
          onClick={async (e) => {
            e.stopPropagation();
            confirmationSwal(
              "Voulez vous envoyer l'email de remerciement ?",
              ""
            ).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await api.post("/sendThanksEmail", {
                    participantId: contact._id,
                  });
                } catch (error) {
                  errorSwal(error);
                  console.error("Error fetching data: ", error);
                }
              }
            });
          }}
        >
          <FaPaperPlane />
        </button>
      </td>
      <td className="px-2 py-4 text-right text-sm font-medium">
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2"
          onClick={async (e) => {
            e.stopPropagation();
            confirmationSwal(
              "Voulez envoyer ou renvoyer l'email de rappel ?",
              ""
            ).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  await api.post("/sendReminder", {
                    participantId: contact._id,
                  });
                } catch (error) {
                  errorSwal(error);
                  console.error("Error fetching data: ", error);
                }
              }
            });
          }}
        >
          <FaRegBell />
        </button>
      </td>
      <td className="px-2 py-4 text-right text-sm font-medium">
        <a href={`/invitation?userId=${contact._id}`} target="_blank">
          <FaFilePdf />
        </a>
      </td>
      <td className="px-4 py-4 text-right text-sm font-medium">
        <button
          className="text-indigo-600 hover:text-indigo-900 mr-2"
          onClick={async (e) => {
            setModifyIsOpen(true);
            setParticipantData(contact);
          }}
        >
          <FaPenToSquare />
        </button>
      </td>
      <td className="px-4 py-4 text-right text-sm font-medium">
        <button
          className="text-red-600 hover:text-red-900 mr-2"
          onClick={async (e) => {
            await deleteParticipant(contact._id);
          }}
        >
          <FaDeleteLeft />
        </button>
      </td>
    </tr>
  );
};

const ContactsList = () => {
  const [participants, setParticipants] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const refreshParticipant = async () => {
    try {
      const participants = await api.get("/participants");
      setParticipants(participants.data);
      setData(participants.data);
    } catch (error) {
      errorSwal(
        "Une erreur s'est produite lors de la récupération des participants"
      );
      console.error("Error fetching data: ", error);
    }
  };
  const refreshParticipantsStatistics = async () => {
    try {
      const participantsStatistics = await api.get(
        "/getParticipantsStatistics"
      );
      setStatistics(participantsStatistics.data);
    } catch (error) {
      errorSwal(
        "Une erreur s'est produite lors de la récupération des statistiques"
      );
      console.error("Error fetching data: ", error);
    }
  };

  const editParticipantEmail = async ({ participantId, email }) => {
    try {
      await api.post("/editEmail", { participantId, email });
      successSwal("L'email a bien été modifiée");
      refreshParticipant();
    } catch (error) {
      console.log(error);
      errorSwal(error || "Une erreur s'est produite");
      console.error("Error fetching data: ", error);
    }
  };

  const deleteParticipant = async (participantId) => {
    confirmationSwal(
      "Voulez-vous vraiment annuler la venue de ce participant ?",
      ""
    ).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.post("/removeParticipant", {
            participantId,
          });
          successSwal("Le participant a bien été retiré de la journée");
          refreshParticipant();
        } catch (error) {
          errorSwal(error);
          console.error("Error fetching data: ", error);
        }
      }
    });
  };

  const refreshParticipantSearch = async () => {
    let newList = [];
    if (search !== "") {
      newList = participants?.filter(
        (participant) =>
          participant?.firstName
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          participant?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
          participant?.email?.toLowerCase().includes(search.toLowerCase()) ||
          participant?.companyImport
            ?.toLowerCase()
            .includes(search.toLowerCase())
      );
    } else if (participants && participants.length) {
      newList = participants;
    }
    setData(newList);
  };

  useEffect(() => {
    refreshParticipant();
  }, []);

  useEffect(() => {
    refreshParticipantSearch();
  }, [search]);

  useEffect(() => {
    refreshParticipantsStatistics();
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: "Nom",
        accessor: "lastName",
      },
      {
        Header: "Prénom",
        accessor: "firstName",
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: (rowA, rowB) =>
          rowA.original.email
            ?.toLowerCase()
            .localeCompare(rowB.original.email?.toLowerCase()),
      },
      { Header: "Entreprise", accessor: "company" },
      {
        Header: "Langue",
        accessor: "isFrench",
        sortType: (rowA, rowB) =>
          rowA.original.isFrench === rowB.original.isFrench
            ? 0
            : rowA.original.isFrench
            ? 1
            : -1,
      },
      {
        Header: "Type",
        accessor: "isNightOnly",
        sortType: (rowA, rowB) =>
          rowA.original.isNightOnly === rowB.original.isNightOnly
            ? 0
            : rowA.original.isNightOnly
            ? 1
            : -1,
      },
      {
        Header: "A répondu",
        accessor: "hasAnswered",
        sortType: (rowA, rowB) =>
          rowA.original.hasAnswered === rowB.original.hasAnswered
            ? 0
            : rowA.original.hasAnswered
            ? 1
            : -1,
      },
      {
        Header: "Cocktail",
        accessor: "participateCocktail",
        sortType: (rowA, rowB) =>
          rowA.original.participateCocktail ===
          rowB.original.participateCocktail
            ? 0
            : rowA.original.participateCocktail
            ? 1
            : -1,
      },
      {
        Header: "Tour",
        accessor: "participateTour",
        sortType: (rowA, rowB) =>
          rowA.original.participateTour === rowB.original.participateTour
            ? 0
            : rowA.original.participateTour
            ? 1
            : -1,
      },
      {
        Header: "Conférence",
        accessor: "participateConference",
        sortType: (rowA, rowB) =>
          rowA.original.participateConference ===
          rowB.original.participateConference
            ? 0
            : rowA.original.participateConference
            ? 1
            : -1,
      },
      {
        Header: "Dîner",
        accessor: "participateDiner",
        sortType: (rowA, rowB) =>
          rowA.original.participateDiner === rowB.original.participateDiner
            ? 0
            : rowA.original.participateDiner
            ? 1
            : -1,
      },
      {
        Header: "Date d'inscription",
        accessor: "updatedAt",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination
  );

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalModifyIsOpen, setModifyIsOpen] = React.useState(false);
  const [participantData, setParticipantData] = React.useState({});

  return (
    <>
      <ModalAddParticipant
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        refreshParticipant={refreshParticipant}
      ></ModalAddParticipant>
      <ModalModifyParticipant
        modalIsOpen={modalModifyIsOpen}
        setIsOpen={setModifyIsOpen}
        refreshParticipant={refreshParticipant}
        participantData={participantData}
      ></ModalModifyParticipant>
      <div className="flex flex-col body-backoffice">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full ">
            <img className="logo-back" src={logo}></img>

            <div className="div-stat">
              <div className="div-victory-pie">
                <div className="victory-pie">
                  <VictoryPie
                    padAngle={({ datum }) => 2}
                    innerRadius={100}
                    data={[
                      {
                        x:
                          statistics.participateAtLeastOneFr +
                          statistics.participateAtLeastOneEn
                            ? statistics.participateAtLeastOneFr +
                              statistics.participateAtLeastOneEn
                            : " ",
                        y:
                          statistics.participateAtLeastOneFr +
                          statistics.participateAtLeastOneEn
                            ? statistics.participateAtLeastOneFr +
                              statistics.participateAtLeastOneEn
                            : 0,
                      },
                      {
                        x:
                          statistics.notAnsweredFr + statistics.notAnsweredEn
                            ? statistics.notAnsweredFr +
                              statistics.notAnsweredEn
                            : " ",
                        y:
                          statistics.notAnsweredFr + statistics.notAnsweredEn
                            ? statistics.notAnsweredFr +
                              statistics.notAnsweredEn
                            : 0,
                      },
                      {
                        x:
                          statistics.answeredNoneParticipateFr +
                          statistics.answeredNoneParticipateEn
                            ? statistics.answeredNoneParticipateFr +
                              statistics.answeredNoneParticipateEn
                            : " ",
                        y:
                          statistics.answeredNoneParticipateFr +
                          statistics.answeredNoneParticipateEn
                            ? statistics.answeredNoneParticipateFr +
                              statistics.answeredNoneParticipateEn
                            : 0,
                      },
                    ]}
                    colorScale={["#F39200", "#aaa9d4", "#fdb940"]}
                  />
                </div>
                <div className="div-stat-color">
                  <label className="label-stat-color">
                    <div className="stat-color-blue"></div>
                    N'a pas répondu
                  </label>
                  <label className="label-stat-color">
                    <div className="stat-color-orange"></div>
                    Participe au moins à 1
                  </label>
                  <label className="label-stat-color">
                    <div className="stat-color-yellow"></div>
                    Ne participe pas
                  </label>
                </div>
              </div>

              <div className="div-stat-activity">
                <div className="div-individual-stat">
                  <FaCocktail className="icon-stat" />
                  <label className="label-stat-icon">
                    Cocktail
                    <p className="stat-number-icon">
                      {(
                        (statistics.participateCocktailFr || 0) +
                        (statistics.participateCocktailEn || 0)
                      ).toString()}
                    </p>
                    <p className="stat-number-icon-en">
                      Dont En{" : "}
                      {(statistics.participateCocktailEn || 0).toString()}
                    </p>
                  </label>
                </div>
                <div className="div-individual-stat">
                  <GiLouvrePyramid
                    className="icon-stat"
                    style={{ margin: "0" }}
                  />
                  <label className="label-stat-icon">
                    Visite du Louvre
                    <p className="stat-number-icon">
                      {(
                        (statistics.participateTourFr || 0) +
                        (statistics.participateTourEn || 0)
                      ).toString()}
                    </p>
                    <p className="stat-number-icon-en">
                      Dont En{" : "}
                      {(statistics.participateTourEn || 0).toString()}
                    </p>
                  </label>
                </div>
                <div className="div-individual-stat">
                  <GiMicrophone className="icon-stat" />
                  <label className="label-stat-icon">
                    Conférences
                    <p className="stat-number-icon">
                      {(
                        (statistics.participateConferenceFr || 0) +
                        (statistics.participateConferenceEn || 0)
                      ).toString()}
                    </p>
                    <p className="stat-number-icon-en">
                      Dont En{" : "}
                      {(statistics.participateConferenceEn || 0).toString()}
                    </p>
                  </label>
                </div>
                <div className="div-individual-stat">
                  <FaBellConcierge className="icon-stat" />
                  <label className="label-stat-icon">
                    Dîner
                    <p className="stat-number-icon">
                      {(
                        (statistics.participateDinerFr || 0) +
                        (statistics.participateDinerEn || 0)
                      ).toString()}
                    </p>
                    <p className="stat-number-icon-en">
                      Dont En{" : "}
                      {(statistics.participateDinerEn || 0).toString()}
                    </p>
                  </label>
                </div>
              </div>
            </div>

            <div className="div-button-send-all">
              <div className="button-Backoffice-div">
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    confirmationSwal(
                      "Voulez renvoyer l'email de rappel ?",
                      ""
                    ).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          await api.post("/sendBatchReminder", {
                            isNightOnly: true,
                            isFrench: false,
                          });
                        } catch (error) {
                          errorSwal(error);
                          console.error("Error fetching data: ", error);
                        }
                      }
                    });
                  }}
                  className="button-backoffice-all"
                >
                  Envoyer rappel EN Soirée
                </button>
              </div>

              <div className="button-Backoffice-div">
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    confirmationSwal(
                      "Voulez renvoyer l'email de rappel ?",
                      ""
                    ).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          await api.post("/sendBatchReminder", {
                            isNightOnly: false,
                            isFrench: false,
                          });
                        } catch (error) {
                          errorSwal(error);
                          console.error("Error fetching data: ", error);
                        }
                      }
                    });
                  }}
                  className="button-backoffice-all"
                >
                  Envoyer rappel EN Tout
                </button>
              </div>

              <div className="button-Backoffice-div">
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    confirmationSwal(
                      "Voulez renvoyer l'email de rappel ?",
                      ""
                    ).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          await api.post("/sendBatchReminder", {
                            isNightOnly: true,
                            isFrench: true,
                          });
                        } catch (error) {
                          errorSwal(error);
                          console.error("Error fetching data: ", error);
                        }
                      }
                    });
                  }}
                  className="button-backoffice-all"
                >
                  Envoyer rappel FR Soirée
                </button>
              </div>

              <div className="button-Backoffice-div">
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    confirmationSwal(
                      "Voulez renvoyer l'email de rappel ?",
                      ""
                    ).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          await api.post("/sendBatchReminder", {
                            isNightOnly: false,
                            isFrench: true,
                          });
                        } catch (error) {
                          errorSwal(error);
                          console.error("Error fetching data: ", error);
                        }
                      }
                    });
                  }}
                  className="button-backoffice-all"
                >
                  Envoyer rappel FR Tout
                </button>
              </div>
            </div>
            <div className="header-back">
              <h1 className="title-back">Backoffice des 60 ans d'Unigrains</h1>

              <div className="div-label">
                <label htmlFor="search" style={{ flex: 2 }}>
                  <input
                    id="Recherche"
                    placeholder="Recherche"
                    type="text"
                    className="search-bar"
                    onChange={handleSearch}
                  />
                </label>

                <div className="button-Backoffice-div">
                  <a className="button-backoffice-all" href="/plan">
                    Plan de table
                  </a>
                </div>
                <ExportCSV users={participants} />
                <div className="button-Backoffice-div">
                  <button
                    className="button-Backoffice"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() => setIsOpen(true)}
                  >
                    Nouveau
                  </button>
                </div>
              </div>
            </div>

            <div className="shadow overflow-hidden border-gray-200 sm:rounded-lg table">
              <table
                className="min-w-full divide-y divide-gray-200"
                {...getTableProps()}
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  className="bg-white divide-y divide-gray-200"
                  {...getTableBodyProps()}
                >
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <ContactCard
                        key={row.values._id}
                        contact={row.original}
                        editParticipantEmail={editParticipantEmail}
                        deleteParticipant={deleteParticipant}
                        setModifyIsOpen={setModifyIsOpen}
                        setParticipantData={setParticipantData}
                      />
                    );
                  })}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                  className="button-backoffice"
                >
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  className="button-backoffice"
                >
                  {"<"}
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  className="button-backoffice"
                >
                  {">"}
                </button>
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                  className="button-backoffice"
                >
                  {">>"}
                </button>
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>

                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                  className="ml-4 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  {[10, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Afficher {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="div-label" style={{ width: "80%" }}>
              <FileUploader
                refreshParticipant={refreshParticipant}
              ></FileUploader>
              <FileHasOpenedReminderUploader
                refreshParticipant={refreshParticipant}
              ></FileHasOpenedReminderUploader>
              <FileHasEnteredUploader
                refreshParticipant={refreshParticipant}
              ></FileHasEnteredUploader>
              <div className="button-Backoffice-div">
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    confirmationSwal(
                      "Voulez vous regénérer les invitations ?",
                      ""
                    ).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          const loader = longLoadingSwal("Génération en cours");
                          await api.post("/generateInvitationsPdf", {});
                          loader.close();
                        } catch (error) {
                          errorSwal(error);
                          console.error("Error fetching data: ", error);
                        }
                      }
                    });
                  }}
                  className="button-Backoffice"
                >
                  Générer Pièce-jointe
                </button>
              </div>
            </div>

            <div className="div-button-send-all">
              <div className="button-Backoffice-div">
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    confirmationSwal(
                      "Voulez-vous envoyer l'email J-3 de remerciement EN ?",
                      ""
                    ).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          await api.post("/sendBatchThanks", {
                            isFrench: false,
                          });
                        } catch (error) {
                          errorSwal(error);
                          console.error("Error fetching data: ", error);
                        }
                      }
                    });
                  }}
                  className="button-backoffice-all"
                >
                  Envoyer remerciement EN
                </button>
              </div>

              <div className="button-Backoffice-div">
                <button
                  onClick={async (e) => {
                    e.stopPropagation();
                    confirmationSwal(
                      "Voulez-vous envoyer l'email de remerciement FR ?",
                      ""
                    ).then(async (result) => {
                      if (result.isConfirmed) {
                        try {
                          await api.post("/sendBatchThanks", {
                            isFrench: true,
                          });
                        } catch (error) {
                          errorSwal(error);
                          console.error("Error fetching data: ", error);
                        }
                      }
                    });
                  }}
                  className="button-backoffice-all"
                >
                  Envoyer remerciement FR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function ModalAddParticipant(props) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };
  const { register, handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState();
  const onSubmit = async (data) => {
    try {
      console.log(data);
      setIsLoading(true);
      await api.post("/createParticipant", data);
      successSwal("Le participant a été ajouté");
      props.setIsOpen(false);
      await props.refreshParticipant();
      reset();
    } catch (e) {
      errorSwal(e);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={() => props.setIsOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
        <div className="header-modal">
          <h1 className="title-modal">Ajouter un nouveau participant</h1>
          <button
            className="text-red-600 hover:text-red-900 mr-2"
            onClick={(e) => {
              e.preventDefault();
              props.setIsOpen(false);
            }}
          >
            <FaTimes />
          </button>
        </div>

        <div className="div-input">
          <label className="label-new-participant">
            Nom :
            <input
              type="text"
              className="input-new-participant"
              {...register("lastName")}
            ></input>
          </label>
          <label className="label-new-participant">
            Prénom :
            <input
              type="text"
              className="input-new-participant"
              {...register("firstName")}
            ></input>
          </label>
        </div>
        <div className="div-input">
          <label className="label-new-participant">
            Civilité :
            <select className="select-new-particpant" {...register("civilite")}>
              <option value="Mme.">Mme</option>
              <option value="Mr">M.</option>
            </select>
          </label>
          <label className="label-new-participant">
            Langue :
            <select className="select-new-particpant" {...register("langue")}>
              <option value="FR">Francais</option>
              <option value="EN">Anglais</option>
            </select>
          </label>
        </div>
        <div className="div-input">
          <label className="label-new-participant">
            Email :
            <input
              type="text"
              className="input-new-participant"
              {...register("email")}
            ></input>
          </label>
          <label className="label-new-participant">
            Type :
            <select className="select-new-particpant" {...register("type")}>
              <option value="TOUT">Tout</option>
              <option value="SOIREE">Soirée</option>
            </select>
          </label>
        </div>

        <button className="button-modal">
          Ajouter{" "}
          {isLoading ? (
            <TailSpin
              height="18"
              width="18"
              color="#000000"
              radius="0"
              wrapperStyle={{
                display: "block",
                margin: "0 auto",
                borderColor: "red",
              }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            ""
          )}
        </button>
      </form>
    </Modal>
  );
}

function ModalModifyParticipant(props) {
  const { participantData } = props;
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };
  const { register, handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState();
  const onSubmit = async (data) => {
    try {
      console.log({ ...data, _id: participantData._id });
      setIsLoading(true);
      await api.post("/editParticipant", { ...data, _id: participantData._id });
      successSwal("Le participant a été ajouté");
      props.setIsOpen(false);
      await props.refreshParticipant();
      reset();
    } catch (e) {
      errorSwal(e);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={() => props.setIsOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
        <div className="header-modal">
          <h1 className="title-modal">Modifier un participant</h1>
          <button
            className="text-red-600 hover:text-red-900 mr-2"
            onClick={(e) => {
              e.preventDefault();
              props.setIsOpen(false);
            }}
          >
            <FaTimes />
          </button>
        </div>

        <div className="div-input">
          <label className="label-new-participant">
            Nom :
            <input
              type="text"
              className="input-new-participant"
              defaultValue={participantData.lastName}
              {...register("lastName")}
            ></input>
          </label>
          <label className="label-new-participant">
            Prénom :
            <input
              type="text"
              className="input-new-participant"
              defaultValue={participantData.firstName}
              {...register("firstName")}
            ></input>
          </label>
        </div>
        <div className="div-input">
          <label className="label-new-participant">
            Civilité :
            <select className="select-new-particpant" {...register("civilite")}>
              <option value="Mme." selected={participantData.isFemale === true}>
                Mme
              </option>
              <option value="Mr" selected={participantData.isFemale === false}>
                M.
              </option>
            </select>
          </label>
          <label className="label-new-participant">
            Langue :
            <select className="select-new-particpant" {...register("langue")}>
              <option value="FR" selected={participantData.isFrench === true}>
                Francais
              </option>
              <option value="EN" selected={participantData.isFrench === false}>
                Anglais
              </option>
            </select>
          </label>
        </div>
        <div className="div-input">
          <label className="label-new-participant">
            Email :
            <input
              type="text"
              className="input-new-participant"
              defaultValue={participantData.email}
              {...register("email")}
            ></input>
          </label>
          <label className="label-new-participant">
            Type :
            <select className="select-new-particpant" {...register("type")}>
              <option
                value="TOUT"
                selected={participantData.isNightOnly === false}
              >
                Tout
              </option>
              <option
                value="SOIREE"
                selected={participantData.isNightOnly === true}
              >
                Soirée
              </option>
            </select>
          </label>
        </div>

        <button className="button-modal">
          Modifier{" "}
          {isLoading ? (
            <TailSpin
              height="18"
              width="18"
              color="#000000"
              radius="0"
              wrapperStyle={{
                display: "block",
                margin: "0 auto",
                borderColor: "red",
              }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            ""
          )}
        </button>
      </form>
    </Modal>
  );
}

export default function Backoffice() {
  return <ContactsList />;
}

const FileUploader = (props) => {
  const importFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.click();
    input.onchange = async function () {
      if (this.files[0]) {
        const formData = new FormData();
        formData.append("file", this.files[0], "file_upload");
        formData.append("upload_file", true);

        try {
          const response = await api.post("/file", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.status === 200) {
            props.refreshParticipant();
          }
        } catch (e) {
          errorSwal(e?.responseJSON?.message);
        }
      }
    };
  };

  return (
    <div className="button-Backoffice-div">
      <button onClick={importFile} className="button-Backoffice">
        Importer
      </button>
    </div>
  );
};
const FileHasOpenedReminderUploader = (props) => {
  const importFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.click();
    input.onchange = async function () {
      if (this.files[0]) {
        const formData = new FormData();
        formData.append("file", this.files[0], "file_upload");
        formData.append("upload_file", true);

        try {
          const response = await api.post("/fileHasOpenedReminder", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.status === 200) {
            props.refreshParticipant();
          }
        } catch (e) {
          errorSwal(e?.responseJSON?.message);
        }
      }
    };
  };

  return (
    <div className="button-Backoffice-div">
      <button onClick={importFile} className="button-Backoffice">
        Importer ouverture du rappel
      </button>
    </div>
  );
};
const FileHasEnteredUploader = (props) => {
  const importFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.click();
    input.onchange = async function () {
      if (this.files[0]) {
        const formData = new FormData();
        formData.append("file", this.files[0], "file_upload");
        formData.append("upload_file", true);

        try {
          const response = await api.post("/fileHasEntered", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.status === 200) {
            props.refreshParticipant();
          }
        } catch (e) {
          errorSwal(e?.responseJSON?.message);
        }
      }
    };
  };

  return (
    <div className="button-Backoffice-div">
      <button onClick={importFile} className="button-Backoffice">
        Importer scan code-barres
      </button>
    </div>
  );
};

const ExportCSV = (props) => {
  const createWorkbook = async () => {
    let workbook = new ExcelJS.Workbook();

    let worksheet = workbook.addWorksheet("My Worksheet");

    const formatBool = (val) => {
      if (val === undefined) {
        return "";
      }
      return val ? 1 : 0;
    };
    worksheet.addRow([
      "Nom",
      "Prénom",
      "Civilité",
      "Email",
      "Entreprise",
      "Langue",
      "Type",
      "Contact Owner",
      "Secteur",
      "A répondu",
      "A annulé",
      "CLUB 12H-16H",
      "VISIT 16H-18H",
      "Billet Nominatif",
      "CONFERENCE 18H-19H30",
      "DINER 19H30-00H",
      "Allergies",
      "Commentaire",
      "Date de modification",
      "Statut",
      "Table",
      "Codes-barres",
      "Présence",
    ]);

    worksheet.addRows(
      props.users.map((u) => {
        return [
          u.lastName,
          u.firstName,
          u.isFemale ? "Mme" : "Mr",
          u.email,
          u.companyImport,
          u.isFrench ? "FR" : "EN",
          u.isNightOnly ? "Soirée" : "Tout",
          u.owner,
          u.sector,
          u.hasAnswered ? 1 : 0,
          !u.participateCocktail &&
          !u.participateTour &&
          !u.participateConference &&
          !u.participateDiner
            ? 1
            : 0,
          formatBool(u.participateCocktail),
          formatBool(u.participateTour),
          u.participateTour ? formatBool(u.louvreTicket) : 0,
          formatBool(u.participateConference),
          formatBool(u.participateDiner),
          u.haveSpecialFood || "",
          u.other || "",
          u.updatedAt || "",
          u.statut || "",
          u.table || "",
          u.barecode || "",
          u.isValidatedDiner ? 1 : 0,
        ];
      })
    );

    let buffer = await workbook.xlsx.writeBuffer();

    let blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Export.xlsx";
    link.click();
  };

  return (
    <div className="button-Backoffice-div">
      <button onClick={createWorkbook} className="button-Backoffice">
        Exporter
      </button>
    </div>
  );
};
