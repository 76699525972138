import axios from "axios";
const apiUrl = process.env.REACT_APP_SERVER_URL;

// Instance Axios qui contiendra le header authorization
// Axios va ajouter /api au début de l'URL de chaque requête, il ne faut donc pas le rajouter dans les appels à l'API
const api = axios.create({
  baseURL: apiUrl,
});

// On supprime le header authorization du store et de l'instance axios si le token est invalide puis on redirige vers la page de connexion
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response.status === 401) {
    // useAuthStore.getState().logout();
    // redirect("/login");
    // }
    return Promise.reject(error?.response?.data?.message);
  }
);

export default api;
