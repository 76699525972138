import Header from "./HeaderPage";
import TitlePages from "../BigTitle";
import TitleArrow from "../TitlewithArrow";
import imgCelebration from "../img/img-celebration.png";
import "../Programme.css";
import Footer from "./Footer";
import visuel1 from "../img/programme/visuel-1.png";
import visuel2 from "../img/programme/visuel2.png";
import visuel3 from "../img/programme/visuel3.png";
import visuel4 from "../img/programme/visuel4.png";
import visuel5 from "../img/programme/visuel-5.png";
import visuel6 from "../img/programme/visuel-6.png";
import visuel7 from "../img/programme/visuel-7.png";
import visuel8 from "../img/programme/CelebrationEn.png";
import portrait1 from "../img/programme/portrait-1.png";
import portrait2 from "../img/programme/portrait-2.png";
import portrait3 from "../img/programme/portrait-3.png";
import portrait4 from "../img/programme/portrait-4.png";
import portrait5 from "../img/programme/portrait-5.png";
import portrait6 from "../img/programme/portrait-6.png";
import portrait7 from "../img/programme/portrait-7.png";
import portrait8 from "../img/programme/portrait-8.png";
import portrait9 from "../img/programme/portrait-9.png";
import chef from "../img/programme/Chief.png";
import lej from "../img/programme/lej.png";
import points from "../img/programme/points.png";
export default function PageProgramme() {
  return (
    <div>
      <Header></Header>
      <TitlePages value="Detailed program"></TitlePages>

      <div className="body-programme-celebration">
        <div style={{ flex: 1 }}>
          <div className="line-programme">
            <TitleArrow value="5PM"></TitleArrow>
            <p className="h4" style={{ marginBottom: 60 }}>
              Door Opening and Welcome Coffee
            </p>
            <TitleArrow value="5:30PM"></TitleArrow>
            <p className="h4">
              Opening of the doors for the 60th Anniversary Celebration
            </p>
            <p className="h2" style={{ marginBottom: 30 }}>
              'Unigrains Today and Tomorrow'
            </p>
            <img className="img-programme-full" src={visuel8}></img>
            <p className="h4-clear"> Featuring interventions from:</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img className="img-programme-full" src={portrait1}></img>
                <p className="name-programme">ERIC THIROUIN</p>
                <p className="function-programme">
                  Chairman and Chief Executive Officer, Unigrains
                </p>
              </div>

              <div>
                <img className="img-programme-full" src={portrait2}></img>
                <p className="name-programme">MAXIME VANDONI</p>
                <p className="function-programme">
                  Deputy Chief Executive Officer, Unigrains
                </p>
              </div>

              <div>
                <img className="img-programme-full" src={portrait3}></img>
                <p className="name-programme">JEAN-FRANÇOIS ISAMBERT</p>
                <p className="function-programme">Vice-President, Unigrains</p>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img className="img-programme-full" src={portrait4}></img>
                <p className="name-programme">RAPHAËL LATZ</p>
                <p className="function-programme">
                  Director, Louis Dreyfus Company
                </p>
              </div>
              <div>
                <img className="img-programme-full" src={portrait5}></img>
                <p className="name-programme">PASCAL LHEUREUX</p>
                <p className="function-programme">
                  President of the Caisse régionale de Normandie, Crédit
                  Agricole
                </p>
              </div>
              <div>
                <img className="img-programme-full" src={portrait9}></img>
                <p className="name-programme">JÉRÔME CALLEAU</p>
                <p className="function-programme">President, InVivo</p>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 80,
                }}
              >
                <div>
                  <img className="img-programme-full" src={portrait6}></img>
                  <p className="name-programme">STANISLAS LEMOR</p>
                  <p className="function-programme">
                    Chairman and Chief Executive Officer, STEF
                  </p>
                </div>
                <div>
                  <img className="img-programme-full" src={portrait7}></img>
                  <p className="name-programme">OLIVIER SERGENT</p>
                  <p className="function-programme">
                    President, TMG et Mecatherm
                  </p>
                </div>
                <div>
                  <img className="img-programme-full" src={portrait8}></img>
                  <p className="name-programme">PIERRE-EMMANUEL TAITTINGER</p>
                  <p className="function-programme">Taittinger</p>
                </div>
              </div>
            </div>
            <TitleArrow value="8PM TO 11:30PM"></TitleArrow>
            <p className="title-bg-programme-top" style={{ marginTop: 80 }}>
              COCKTAIL AROUND
            </p>
            <p className="title-bg-programme-bottom">
              THE GASTRONOMY OF OUR REGIONS
            </p>
            <p className="title-bg-programme-top">
              60TH ANNIVERSARY CELEBRATION
            </p>
            <li className="li-programme" style={{ marginTop: 15 }}>
              Seated diner in the “Le Nôtre” ballroom
            </li>
            <li className="li-programme">
              Signature menu imagined by a renowned chef
            </li>
            <img
              className="img-programme-intermediate"
              src={chef}
              style={{ marginBottom: 40, marginTop: 15 }}
            ></img>
            <li className="li-programme">
              LEJ's Private Concert: '60 Years of Songs
            </li>

            <img
              className="img-programme-intermediate"
              src={lej}
              style={{ marginBottom: 50, marginTop: 15 }}
            ></img>
            <TitleArrow value="11:30PM to 1AM"></TitleArrow>
            <p className="title-bg-programme-top">Unigrains’ Lounge</p>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
