import Header from "./HeaderPage";
import "../HeaderFR.css";
import "../ConfirmationFR.css";
import programmeTout from "../img/programme-tout.png";
import Footer from "./Footer";

export default function PageConfirmation() {
  return (
    <div>
      <Header></Header>
      <div className="body">
        {/* <Programme></Programme> */}

        <div className="title">
          <h1 className="title-h1">Unigrains 60th anniversary celebration</h1>
          <h2 className="title-h2">
            October 5th 2023 at les Salles du Carrousel du Louvre
          </h2>
          <p className="light" style={{ margin: "70px", fontSize: "20px" }}>
            Thank you for your answer!
            <br /> We will get back tou you very soon.
            <br />
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

function Programme() {
  return (
    <div className="programme">
      <img className="programme-long" src={programmeTout}></img>
    </div>
  );
}
