import imgPrincipale from "./img/img-solo.png";
import logo from "./img/logo.png";
import frise from "./img/frise.png";
import demiRond from "./img/demi-rond-seul.png";
import rondJaune from "./img/Rond-jaune.png";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useState } from "react";
export default function Header() {
  return (
    <div>
      <TopPage></TopPage>
      <Navbar></Navbar>
    </div>
  );
}

export function TopPageThanks() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const parts = currentPath.split("/");
  let basePath;
  if (["60", "celebration"].includes(parts[1])) {
    basePath = "/" + parts[1];
  } else {
    basePath = "/60";
  }
  return (
    <div
      className="top-page"
      style={{ backgroundImage: `url(${imgPrincipale})` }}
    >
      <div className="div-logo">
        <img
          className="logo"
          onClick={() => {
            navigate(basePath);
          }}
          src={logo}
        ></img>
        <div className="div-title">
          <h1 className="title-header">Jeudi 5 Octobre 2023</h1>
          <h1 className="subtitle-header">Merci pour votre participation ! </h1>
        </div>
      </div>
    </div>
  );
}
export function TopPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const parts = currentPath.split("/");
  let basePath;
  if (["60", "celebration"].includes(parts[1])) {
    basePath = "/" + parts[1];
  } else {
    basePath = "/60";
  }
  return (
    <div
      className="top-page"
      style={{ backgroundImage: `url(${imgPrincipale})` }}
    >
      <div className="div-logo">
        <img
          className="logo"
          onClick={() => {
            navigate(basePath);
          }}
          src={logo}
        ></img>
        <div className="div-title">
          <h1 className="title-header">Jeudi 5 Octobre 2023</h1>
          <h1 className="subtitle-header">Les Salles du Carrousel du Louvre</h1>
        </div>
      </div>
      <div className="div-frise">
        <img className="frise" src={frise}></img>
        <img className="demi-rond" src={demiRond}></img>
      </div>
    </div>
  );
}
function MenuButtons() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const parts = currentPath.split("/");
  let basePath;
  if (["60", "celebration"].includes(parts[1])) {
    basePath = "/" + parts[1];
  } else {
    basePath = "/60";
  }

  return (
    <>
      <button
        onClick={() => {
          navigate(basePath);
        }}
        className={
          [
            "/60/",
            "/60",
            "/60/confirmation/",
            "/60/confirmation",
            "/60/confirmation-later/",
            "/60/confirmation-later",
            "/celebration/",
            "/celebration",
            "/celebration/confirmation/",
            "/celebration/confirmation",
            "/celebration/confirmation-later/",
            "/celebration/confirmation-later",
          ].includes(currentPath)
            ? "btn-navbar-select"
            : "btn-navbar"
        }
      >
        Inscription
      </button>
      <button
        onClick={() => {
          navigate(`${basePath}/programme`);
        }}
        className={
          [
            "/60/programme",
            "/60/programme/",
            "/celebration/programme",
            "/celebration/programme/",
          ].includes(currentPath)
            ? "btn-navbar-select"
            : "btn-navbar"
        }
      >
        Programme
      </button>
      <button
        onClick={() => {
          navigate(`${basePath}/access`);
        }}
        className={
          [
            "/60/access",
            "/60/access/",
            "/celebration/access",
            "/celebration/access/",
          ].includes(currentPath)
            ? "btn-navbar-select"
            : "btn-navbar"
        }
      >
        Accès
      </button>
      <button
        onClick={() => {
          navigate(`${basePath}/hotels`);
        }}
        className={
          [
            "/60/hotels",
            "/60/hotels/",
            "/celebration/hotels",
            "/celebration/hotels/",
          ].includes(currentPath)
            ? "btn-navbar-select"
            : "btn-navbar"
        }
      >
        Hébergement
      </button>
      <button
        onClick={() => {
          navigate(`${basePath}/contact`);
        }}
        className={
          [
            "/60/contact",
            "/60/contact/",
            "/celebration/contact",
            "/celebration/contact/",
          ].includes(currentPath)
            ? "btn-navbar-select"
            : "btn-navbar"
        }
      >
        Contact
      </button>
    </>
  );
}

function Navbar() {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div className="navbar">
      <div className="sandwich-menu">
        <button
          onClick={handleClick}
          className={open ? "menu-button open" : "menu-button"}
        >
          <div className="menu-line" />
          <div className="menu-line" />
          <div className="menu-line" />
        </button>
        {open && (
          <div className="menu-items">
            <MenuButtons></MenuButtons>
          </div>
        )}
      </div>
      <div className="btns-navbar">
        <MenuButtons></MenuButtons>
        <img className="rond-jaune" src={rondJaune}></img>
      </div>
    </div>
  );
}
