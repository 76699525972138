import "./Cgu.css";
import Header from "./HeaderPage";
import TitlePages from "./BigTitle";
import TitleArrow from "./TitlewithArrow";
import Footer from "./Footer.js";

export default function CguPage() {
  return (
    <div>
      <Header></Header>

      <TitlePages value="Conditions générales d’utilisation"></TitlePages>
      <div className="div-cgu">
        <p className="light">
          Le site des 60 Unigrains est un site édité indépendamment par ARKONE
          SAS, au capital de 5000 euros, dont le siège social est situé au 105
          rue de Longchamp, 92200 Neuilly-Sur-Seine, France immatriculée au
          Registre du Commerce et des Sociétés de Paris sous le numéro
          892975368.
          <br /> <br /> Les présentes Conditions Générales d’Utilisation
          (ci-après « CGU ») ont pour objet de fixer les règles d’utilisation du
          site des 60 ans d’Unigrains par les Utilisateurs.
          <br /> <br /> L’utilisation du site suppose l’acceptation par
          l’Utilisateur des présentes CGU.
          <br /> <br /> En y accédant, vous acceptez l’intégralité des présentes
          CGU. Pour toute question relative aux CGU ou, à l’utilisation du site
          web, vous pouvez nous écrire à l’adresse suivante :
          arkone-france@outlook.fr <br /> <br /> L’Editeur est libre de modifier
          les CGU à tout moment. Il est donc conseillé à l’Utilisateur de se
          référer régulièrement à la dernière version des CGU.
        </p>
      </div>
      <div className="div-cgu">
        <TitleArrow value="DEFINITIONS"></TitleArrow>
        <p className="light">On désignera par la suite :</p>
        <p className="light">
          « Site » : le site des 60 ans Unigrains et l’ensemble de ses pages et
          écrans. <br /> <br />
          « Base de données » : la base de données des informations de
          l’événement utilisée dans le Site. <br /> <br />
          « Editeur » : la personne morale ou physique, responsable de l’édition
          et du contenu du Site. <br /> <br />
          « Utilisateur » : la personne utilisant le Site. <br /> <br />«
          Partenaires » : les entreprises : Franco American Image au capital de
          99 170 euros , dont le siège social est situé au 99 rue de la
          république, 92800 Puteaux, France immatriculée au Registre du Commerce
          et des Sociétés de Nanterre sous le numéro 411 032 105 et UNIGRAINS,
          société anonyme au capital social de 7 000 000€ , dont le siège social
          est situé au 23 avenue de Neuilly, 75116 Paris France immatriculée au
          Registre du Commerce et des Sociétés de Paris sous le numéro XXXXXXX,
          responsables de l’événement pour lesquels nous agissons en qualité de
          sous-traitant.
        </p>
      </div>
      <div className="div-cgu">
        <TitleArrow value="OBJET"></TitleArrow>
        <p className="light">
          Le site a pour but de présenter et de permettre l’inscription au 60
          Unigrains uniquement aux personnes invitées.
        </p>
      </div>
      <div className="div-cgu">
        <TitleArrow value="CONDITIONS D’UTILISATION"></TitleArrow>
        <h2 className="h2-cgu">Accès au Site Web</h2>
        <p className="light">
          L’utilisation du Site et de leurs services sont gratuites et limitée à
          l’Union Européenne.
        </p>
        <p className="light">
          Le site est accessible à tout le monde mais la validation du
          formulaire est restreinte aux personnes ayant été invitées et dont
          l’email est enregistré dans la base de données fourni par Unigrains.
        </p>
        <p className="light">
          Une fois l’inscription réalisée depuis le site, il n’est plus possible
          de modifier ses informations.
        </p>
        <p className="light">
          Une fois l’inscription réalisée depuis le site, il n’est plus possible
          de modifier ses informations. Il appartient à l’Utilisateur de prendre
          toutes les dispositions nécessaires permettant de protéger ses propres
          données contre toute atteinte. En cas de problèmes techniques ou pour
          des raisons de maintenance, l’accessibilité au Site pourra être
          perturbée et/ou suspendue.
          <br />
          L’Editeur utilisera les coordonnées communiquées par l’Utilisateur et
          ses Partenaires lors de l’inscription à un compte personnel. A défaut
          l’Editeur ne sera pas en mesure de lui adresser les différentes
          informations et alertes prévues dans le cadre des présentes Conditions
          et ne pourra en être tenu responsable.
          <br />
          Sous réserve des éventuelles pannes et interventions de maintenance
          nécessaires au bon fonctionnement de la Plateforme ainsi que des cas
          de force majeure, l’Editeur s’efforce de permettre l’accès au Site 24
          heures sur 24, 7 jours sur 7.
          <br />
        </p>
        <p className="light">
          Toutefois, l’Editeur ne peut garantir une disponibilité du Site et des
          services et se réserve le droit d’interrompre temporairement ou
          définitivement l’accès à tout ou partie du Site ou tout ou partie des
          services, à tout moment, sans préavis.
        </p>
        <p className="light">
          La responsabilité de l’Editeur ne saurait être engagée de ces faits.
          En cas d’interruption, l’Utilisateur reconnaît et accepte que
          l’Editeur n’est pas responsable des conséquences qui peuvent en
          découler pour lui-même ou tout tiers.
        </p>
        <h2 className="h2-cgu">Propriété intellectuelle</h2>
        <p className="light">
          L’usage exclusif des marques, des logos, des logiciels, des éléments
          graphiques, des bases de données, et plus généralement de tous
          contenus proposés par l’Application sont la propriété de L’Editeur, ou
          de ses Partenaires et sont donc protégés par le droit d’auteur et le
          droit des marques
        </p>
        <h2 className="h2-cgu">Principes de sécurité</h2>
        <p className="light">
          L’Editeur se réserve la faculté de suspendre l’accès de l’Utilisateur
          en cas de risque concernant la sécurité du système informatique ou de
          présomption d’utilisation non autorisée ou frauduleuse du service.{" "}
          <br />
          L’Utilisateur est entièrement responsable de l’usage, de la
          conservation et de la confidentialité du site, et s’engage à informer
          l’Editeur de toute suspicion d’utilisation frauduleuse de son Espace
          en ligne.
        </p>
        <h2 className="h2-cgu">Droits et Obligations de l’Editeur</h2>
        <p className="light">
          L’Utilisateur admet expressément utiliser le Site à ses propres
          risques et sous sa responsabilité exclusive.
        </p>
        <h2 className="h2-cgu">Autres</h2>
        <p className="light">
          L’Editeur n’assume aucune responsabilité quant aux dommages
          éventuellement causés en cas d’utilisation frauduleuse du Site, en cas
          de communication d’informations inexactes ou incomplètes par
          l’Utilisateur lors de l’utilisation des services à distance.
        </p>
        <p className="light">
          L’Utilisateur est le seul responsable des coûts additionnels ou
          accessoires qui résultent de son usage de la Plateforme, ceci incluant
          notamment tout coût de communication, d’acquisition de logiciel et/ou
          d’équipement, de réparation ou de stockage de données, de location ou
          d’utilisation d’une licence, etc. En aucun cas la prise en charge de
          tels coûts ne peut être demandée à l’Editeur ou constituer un
          préjudice indemnisable.
        </p>
        <p className="light">
          De même, la responsabilité de l’Editeur ne saurait être engagée si
          l’utilisation de ces sites, par l’Utilisateur, lui causait un
          préjudice.
        </p>
      </div>
      <div className="div-cgu">
        <TitleArrow value="COLLECTE DES DONNEES"></TitleArrow>
        <p className="light">
          Conformément au Réglement Général de Protection des Données (RGPD), la
          collecte et le traitement des données respectent les principes
          suivants : Licéité, loyauté et transparence, finalités limitées,
          conservation des données réduites dans le temps, intégrité et
          confidentialité des données collectées et traitées.
        </p>
        <p className="light">
          L’Utilisateur peut utiliser son droit d’accès, de rectification,
          d’effacement et à la portabilité des données les concernant et peut
          également, pour des motifs légitimes, s’opposer au traitement et, dans
          les cas prévus par la loi, demander la limitation du traitement de ces
          données.
        </p>
        <h2 className="h2-cgu">Systèmes de collectes de données</h2>
        <h3 className="h3-cgu">Site web</h3>
        <p className="light">
          Lors de la création d’un compte sur le site web, les données suivantes
          sont collectées pour l’Utilisateur :
        </p>
        <p className="light">
          Civilité, nom, prénom, email, entreprise, liste des participations,
          spécificités alimentaires et questions annexes.
        </p>
        <h2 className="h2-cgu">Destinataire des données</h2>
        <p className="light">
          Les données à caractère personnel strictement nécessaires à
          l’accomplissement de leurs missions sont transmises aux services
          habilités de l’Editeur, à savoir les services informatique, juridique
          et à la direction.
        </p>
        <p className="light">
          Par ailleurs, elles peuvent être communiquées à des prestataires
          agissant en qualité de sous-traitants.
        </p>
        <p className="light">
          De même, les données seront partagées avec nos Partenaires pour la
          bonne gestion de l’événement.
        </p>
        <p className="light">
          L’Editeur assure la conformité avec les exigences de protection des
          données pour toutes ses sociétés sous-traitantes. Pour autant,
          l’Editeur reste libre du choix de ses sous-traitants techniques et
          commerciaux à la condition qu’ils présentent les garanties suffisantes
          au regard des exigences de la Réglementation. Aussi, l’Editeur n’est
          pu responsable du respect de la conformité avec les exigences de
          protection des données lorsque les données sont transmises à leurs
          Partenaires.
        </p>
        <p className="light">
          Enfin, lorsque cela est requis, les données peuvent être communiquées
          aux autorités administratives ou judiciaires.
        </p>
        <h2 className="h2-cgu">Durée de conservation de données</h2>
        <p className="light">
          Les données sont conservées jusqu’à ce que leurs finalités soient
          atteintes.
        </p>
        <h2 className="h2-cgu">Droits des utilisateurs sur les données</h2>
        <p className="light">
          Par ailleurs, conformément à la Réglementation, l’Utilisateur dispose
          des droits suivants :<br />
          <br /> Droit d’accès aux données Droit de rectification
          <br />
          Droit d’opposition <br />
          Droit à l’effacement de données <br />
          Droit à la portabilité des données <br />
          Droit à la limitation du traitement <br />
          Droit de retirer le consentement
          <br /> <br />
          Pour exercer ces droits, l’Utilisateur doit contacter l’Editeur à
          l’adresse électronique suivante : 
          <a href="mailto:arkone-france@outlook.fr">arkone-france@outlook.fr</a>
        </p>
      </div>

      <Footer></Footer>
    </div>
  );
}
