import "./App.css";
import "./Form-tout.css";

import FormTout from "./FormTout";
import FormSoiree from "./FormSoiree";
import AccesPage from "./AccesPage";
import Confirmation from "./ConfirmationPage";
import PageConfirmationDontKnow from "./ConfirmationPageDontKnow";
import HotelsPage from "./HotelPage";
import PageProgrammeSoiree from "./ProgrammeSoiree";
import PageProgrammeTout from "./ProgrammeTout";
import Contact from "./Contact";

import FormToutEn from "./en/FormTout";
import FormSoireeEn from "./en/FormSoiree";
import AccesPageEn from "./en/AccessPage";
import ConfirmationEn from "./en/ConfirmationPage";
import PageConfirmationDontKnowEn from "./en/ConfirmationPageDontKnow";
import HotelsPageEn from "./en/HotelPage";
import PageProgrammeEnSoiree from "./en/ProgrammeSoiree";
import PageProgrammeEnTout from "./en/ProgrammeTout";
import ContactEn from "./en/Contact";
import HomePageEn from "./en/Home";

import CguPageEn from "./en/Cgu";
import MentionsLegalesPageEn from "./en/MentionsLegales";

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MentionsLegalesPage from "./MentionsLegales";
import CguPage from "./Cgu";
import HomePage from "./Home";
import Backoffice from "./Backoffice";
import Plan from "./Plan.tsx";
import PageLogIn from "./Login";
import AuthContainer from "./auth/AuthContainer";
import PicturesPages from "./PicturesPage";
import PicturesPagesEN from "./en/PicturesPage";

const routes = [
  {
    label: "Home",
    path: "/",
    element: <HomePage />,
  },
  {
    label: "PicturesPages",
    path: "/pictures",
    element: <PicturesPages />,
  },
  {
    label: "PicturesPages",
    path: "/en/pictures",
    element: <PicturesPagesEN />,
  },
  {
    label: "Backoffice",
    path: "/back-uni-office",
    element: <Backoffice />,
    requireAuth: true,
  },
  {
    label: "Plan",
    path: "/plan",
    element: <Plan />,
    requireAuth: true,
  },
  { label: "Login", path: "/login", element: <PageLogIn /> },
  {
    label: "60",
    path: "/60",
    element: <FormTout />,
  },
  {
    label: "Celebration",
    path: "/celebration",
    element: <FormSoiree />,
  },
  {
    label: "Programme",
    path: "/60/programme",
    element: <PageProgrammeTout />,
  },

  {
    label: "Programme",
    path: "/celebration/programme",
    element: <PageProgrammeSoiree />,
  },

  {
    label: "Contact",
    path: "/celebration/contact",
    element: <Contact />,
  },
  {
    label: "Contact",
    path: "/60/contact",
    element: <Contact />,
  },

  {
    label: "AccesPage",
    path: "/celebration/access",
    element: <AccesPage />,
  },
  {
    label: "AccesPage",
    path: "/60/access",
    element: <AccesPage />,
  },
  {
    label: "Confirmation",
    path: "/celebration/confirmation",
    element: <Confirmation />,
  },
  {
    label: "Confirmation",
    path: "/60/confirmation",
    element: <Confirmation />,
  },
  {
    label: "PageConfirmationDontKnow",
    path: "/celebration/confirmation-later",
    element: <PageConfirmationDontKnow />,
  },
  {
    label: "PageConfirmationDontKnow",
    path: "/60/confirmation-later",
    element: <PageConfirmationDontKnow />,
  },

  {
    label: "HotelsPage",
    path: "/celebration/hotels",
    element: <HotelsPage />,
  },
  {
    label: "HotelsPage",
    path: "/60/hotels",
    element: <HotelsPage />,
  },
  {
    label: "MentionsLegales",
    path: "/celebration/mentions-legales",
    element: <MentionsLegalesPage />,
  },
  {
    label: "MentionsLegales",
    path: "/60/mentions-legales",
    element: <MentionsLegalesPage />,
  },

  {
    label: "Cgu",
    path: "/celebration/cgu",
    element: <CguPage />,
  },
  {
    label: "Cgu",
    path: "/60/cgu",
    element: <CguPage />,
  },

  {
    label: "HomeEn",
    path: "/en",
    element: <HomePageEn />,
  },
  {
    label: "60En",
    path: "/en/60",
    element: <FormToutEn />,
  },
  {
    label: "CelebrationEn",
    path: "/en/celebration",
    element: <FormSoireeEn />,
  },

  {
    label: "ProgrammeEn",
    path: "/en/celebration/programme",
    element: <PageProgrammeEnSoiree />,
  },
  {
    label: "ProgrammeEn",
    path: "/en/60/programme",
    element: <PageProgrammeEnTout />,
  },

  {
    label: "ContactEn",
    path: "/en/celebration/contact",
    element: <ContactEn />,
  },
  {
    label: "ContactEn",
    path: "/en/60/contact",
    element: <ContactEn />,
  },

  {
    label: "AccesPageEn",
    path: "/en/celebration/access",
    element: <AccesPageEn />,
  },
  {
    label: "AccesPageEn",
    path: "/en/60/access",
    element: <AccesPageEn />,
  },
  {
    label: "ConfirmationEn",
    path: "/en/celebration/confirmation",
    element: <ConfirmationEn />,
  },
  {
    label: "ConfirmationEn",
    path: "/en/60/confirmation",
    element: <ConfirmationEn />,
  },
  {
    label: "PageConfirmationDontKnowEn",
    path: "/en/celebration/confirmation-later",
    element: <PageConfirmationDontKnowEn />,
  },
  {
    label: "PageConfirmationDontKnowEn",
    path: "/en/60/confirmation-later",
    element: <PageConfirmationDontKnowEn />,
  },

  {
    label: "HotelsPageEn",
    path: "/en/celebration/hotels",
    element: <HotelsPageEn />,
  },
  {
    label: "HotelsPageEn",
    path: "/en/60/hotels",
    element: <HotelsPageEn />,
  },

  {
    label: "MentionsLegales",
    path: "/en/celebration/mentions-legales",
    element: <MentionsLegalesPageEn />,
  },
  {
    label: "MentionsLegales",
    path: "/en/60/mentions-legales",
    element: <MentionsLegalesPageEn />,
  },

  {
    label: "Cgu",
    path: "/en/celebration/cgu",
    element: <CguPageEn />,
  },
  {
    label: "Cgu",
    path: "/en/60/cgu",
    element: <CguPageEn />,
  },

  { path: "/en/*", element: <HomePageEn replace />, label: "404" },

  { path: "*", element: <HomePage replace />, label: "404" },
];

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {routes.map((route) => {
            return route.requireAuth ? (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <AuthContainer requireAuth={true}>
                    {route.element}
                  </AuthContainer>
                }
              />
            ) : (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
