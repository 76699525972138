import ExcelJS from "exceljs";
import image from "./img/logo.png";

const headersPlan = ["NOM", "Prénom", "Table"];
let letters = [];
const indexStart = 10;
const indexStartPlan = 7;
const nbrItemPerpage = 30;
const nbrItemPerpagePlan = 41;
const pageLength = nbrItemPerpage + indexStart;
const pageLengthPlan = nbrItemPerpagePlan + indexStartPlan;

const planHeaderCommon = {
  merges: [
    { start: { row: 1, col: 3 }, end: { row: 1, col: 7 } },
    { start: { row: 3, col: 5 }, end: { row: 5, col: 6 } },
  ],
  images: [
    {
      path: image,
      start: { row: 2.5, col: 0 },
      end: { row: 8.5, col: 2 },
    },
  ],
};

const border = {
  top: { style: "thin" },
  left: { style: "thin" },
  bottom: { style: "thin" },
  right: { style: "thin" },
};

const getBase64Image = (url) =>
  new Promise((res) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      res(dataURL);
    };
    img.src = url;
  });

const displayRow = ({ rowItem, rowIndex, col, isHeader, ws, isPlan }) => {
  let indexStart;

  indexStart = col * rowItem.length;
  if (isPlan && col == 1) {
    indexStart += 1;
  }
  let hasAllergies = false;
  const row = ws.getRow(rowIndex);

  rowItem.map((item, i) => {
    row.getCell(i + indexStart + 1).value = item;
    row.getCell(i + indexStart + 1).font = { size: 16 };
    if (isHeader) {
      row.getCell(i + indexStart + 1).font = { bold: true, size: 16 };
      row.getCell(i + indexStart + 1).alignment = {
        horizontal: "centerContinuous",
        vertical: "middle",
      };
    }

    if (hasAllergies) {
      row.getCell(i + indexStart + 1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F08080" },
      };
    }

    if (i != 0 || isPlan) {
      row.getCell(i + indexStart + 1).border = border;
    }
  });

  if (isPlan) {
    row.getCell(indexStart + 1).font = { bold: true };
    row.getCell(indexStart + 3).font = { bold: true };
    row.getCell(indexStart + 3).alignment = {
      horizontal: "centerContinuous",
    };
  }
  if (col == 1 && rowIndex % pageLength == 0) {
    row.addPageBreak();
  }
  row.commit();
};

export const generatePlanExcel = async ({ data, tables }) => {
  let wb = new ExcelJS.Workbook();

  let ws = wb.addWorksheet("Affichage");
  const header = {
    contents: [
      {
        row: 1,
        col: 5,
        prop: "value",
        value: {
          richText: [
            {
              font: {
                size: 26,
                color: { argb: "aaa9d4" },
                bold: true,
              },
              text: "Jeudi 05 octobre 2023",
            },
          ],
        },
      },
      {
        row: 1,
        col: 5,
        prop: "alignment",
        value: { vertical: "middle", horizontal: "right" },
      },
    ],
  };
  const headerCommon = planHeaderCommon;

  letters = [];
  const colIndices = { 0: indexStartPlan, 1: indexStartPlan };
  data = data.map((u) => [u.lastName, u.firstName, u.table]);
  const setUpHeader = async (pageIndex = 0) => {
    for (const merge of headerCommon.merges) {
      const { start, end } = merge;
      ws.mergeCells(
        start.row + pageIndex * pageLengthPlan,
        start.col,
        end.row + pageIndex * pageLengthPlan,
        end.col
      );
    }

    for (const { row, col, value, prop } of header.contents) {
      ws.getRow(row + pageIndex * pageLengthPlan).getCell(col)[prop] = value;
    }
    for (const { path, start, end } of headerCommon.images) {
      const id = wb.addImage({
        base64: await getBase64Image(path),
        extension: "png",
      });
      ws.addImage(id, {
        // tl: { row: start.row + pageIndex * pageLength, col: start.col },
        // br: { row: end.row + pageIndex * pageLength, col: end.col },
        tl: { col: 0.1, row: pageIndex * pageLengthPlan },
        ext: { width: 250, height: 125 },
        editAs: undefined,
      });
    }
  };
  const pageCount = Math.ceil(data.length / (nbrItemPerpagePlan * 2));
  for (let i = 0; i < pageCount; i++) {
    await setUpHeader(i);
  }
  data.map((d, i) => {
    const col = Math.floor(i / nbrItemPerpagePlan) % 2 == 0 ? 0 : 1;
    if (i % (nbrItemPerpagePlan * 2) == 0) {
      letters.push({ first: d[0].slice(0, 2) });
    }
    if ((i + 1) % (nbrItemPerpagePlan * 2) == 0 || i == data.length - 1) {
      letters[letters.length - 1].last = d[0].slice(0, 2);
    }
    if (i % nbrItemPerpagePlan == 0) {
      displayRow({
        rowItem: headersPlan,
        rowIndex: colIndices[col],
        col,
        isHeader: true,
        ws,
        isPlan: true,
      });
      colIndices[col]++;
    }
    displayRow({
      rowItem: d,
      rowIndex: colIndices[col],
      col,
      ws,
      isPlan: true,
      bold: true,
    });

    if (colIndices[col] % pageLengthPlan == 0) {
      colIndices[col] += indexStartPlan;
    } else {
      colIndices[col]++;
    }
  });

  if (ws.columns) {
    ws.columns.forEach(function (column, i) {
      if ([2, 3, 6].includes(i)) {
        column.width = 5;
      }

      if ([0, 1, 4, 5].includes(i)) {
        column.width = 25;
      }
    });
  }

  ws.pageSetup.fitToPage = true;

  for (let i = 0; i < pageCount; i++) {
    ws.getCell(`E${i * pageLengthPlan + 3}`).value = `${
      letters[i].first[0]
    }${letters[i].first[1].toLowerCase()}  -  ${letters[i].last[0]}${letters[
      i
    ].last[1].toLowerCase()}`;
    ws.getCell(`E${i * pageLengthPlan + 3}`).font = {
      size: 34,
      color: { argb: "fdb940" },
      bold: true,
    };
    let row = ws.getRow(1 + i * pageLengthPlan);
    row.height = 60;

    row = ws.getRow(5 + i * pageLengthPlan);
  }
  ws.pageSetup.fitToPage = true;
  ws.pageSetup.fitToWidth = 1;
  ws.pageSetup.margins = {
    left: 0.7,
    right: 0.7,
    top: 0.75,
    bottom: 0.75,
    header: 0.3,
    footer: 0.3,
  };

  //   const wsPlan = wb.addWorksheet("My Worksheet");

  //   let
  //   for(const table of tables)

  generateSheetTable1({ wb, tables });
  generateSheetTable2({ wb, tables });

  let buffer = await wb.xlsx.writeBuffer();

  let blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = "Export.xlsx";
  link.click();
  console.log("here");
};

const generateSheetTable1 = ({ wb, tables }) => {
  const worksheet = wb.addWorksheet("Tables");

  let currentRow = 1;
  let currentColumn = 1;

  let cpt = 1;
  for (let i = 0; i < tables.length; i++) {
    const table = tables[i];

    // Print the table name and index
    worksheet.getCell(currentRow, currentColumn).value =
      table.name + " (" + cpt + ")";
    worksheet.getCell(currentRow, currentColumn).font = { bold: true };

    currentRow++;

    // Print the user details: firstName, lastName, companyImport, isFrench, and sector
    table.data.forEach((user) => {
      worksheet.getCell(currentRow, currentColumn).value = user.firstName;
      worksheet.getCell(currentRow, currentColumn + 1).value = user.lastName;
      worksheet.getCell(currentRow, currentColumn + 2).value =
        user.companyImport;
      worksheet.getCell(currentRow, currentColumn + 3).value = user.sector;
      worksheet.getCell(currentRow, currentColumn + 4).value = user.isFrench
        ? "FR"
        : "EN";

      currentRow++;
    });

    // Check if this is the fifth table in this row
    if ((i + 1) % 4 === 0) {
      currentRow += 12 - table.data.length + 2; // 2 blank rows between each set of 5 tables
      currentColumn = 1; // reset to the first column
    } else {
      currentColumn += 5; // adjust for the 5 columns (firstName, lastName, companyImport, isFrench, sector) + 1 blank column
      currentRow = currentRow - table.data.length - 1; // move back up to start the next table at the same level
    }
    cpt++;
  }
};

const generateSheetTable2 = ({ wb, tables }) => {
  const worksheet = wb.addWorksheet("Tables 2");

  let currentRow = 1;
  let currentColumn = 1;

  let cpt = 1;
  for (let i = 0; i < tables.length; i++) {
    const table = tables[i];

    // Print the table name and index
    worksheet.getCell(currentRow, currentColumn).value =
      table.name + " (" + cpt + ") " + table.data.length + " pax";
    worksheet.getCell(currentRow, currentColumn).font = { bold: true };

    currentRow++;

    // Print the user details: firstName, lastName, companyImport, isFrench, and sector
    table.data.forEach((user) => {
      worksheet.getCell(currentRow, currentColumn).value = user.firstName;
      worksheet.getCell(currentRow, currentColumn + 1).value = user.lastName;
      worksheet.getCell(currentRow, currentColumn + 2).value =
        user.companyImport;
      worksheet.getCell(currentRow, currentColumn + 3).value =
        user.haveSpecialFood;

      currentRow++;
    });

    // Check if this is the fifth table in this row
    if ((i + 1) % 2 === 0) {
      currentRow += 12 - table.data.length + 2; // 2 blank rows between each set of 5 tables
      currentColumn = 1; // reset to the first column
    } else {
      currentColumn += 5; // adjust for the 5 columns (firstName, lastName, companyImport, ) + 1 blank column
      currentRow = currentRow - table.data.length - 1; // move back up to start the next table at the same level
    }
    cpt++;
  }
};
