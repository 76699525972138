import { useEffect, useState } from "react";
import { TopPage, TopPageThanks } from "./HeaderPage";
import api from "./api";
import getErrorDic from "./getErrorDic";
import swal from "./swal";
import imgPrincipale from "./img/img-solo.png";
import "./PicturesPage.css";
import { FaDownload } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import frise from "./img/frise.png";
import demiRond from "./img/demi-rond-seul.png";
import Footer from "./Footer";
const getErrorMessage = getErrorDic("fr");
export default function PicturesPages() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  // const numberPicturesPerPage = 16;
  // const [pictures, setPictures] = useState();
  // const [selectedPage, setSelectedPage] = useState();
  // const [picturesOptions, setPicturesOptions] = useState([]);
  // const [displayedPictures, setDisplayedPictures] = useState([]);
  // const [zoomedImageUrl, setZoomedImageUrl] = useState(null);
  // const [index, setIndex] = useState(0);

  // const openZoomedImage = (imageUrl) => {
  //   setZoomedImageUrl(imageUrl);
  // };

  // const closeZoomedImage = () => {
  //   setZoomedImageUrl(null);
  // };
  // const handleDownload = (imageUrl) => {
  //   const link = document.createElement("a");
  //   link.href = imageUrl;
  //   link.setAttribute("download", "desired-filename.jpg"); // Ajout de nom de fichier
  //   link.setAttribute("target", "_blank");
  //   link.setAttribute("rel", "nofollow noreferrer");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  // useEffect(() => {
  //   const getPictures = async () => {
  //     if (!token) {
  //       return;
  //     }
  //     try {
  //       const res = await api.get("/getPictures", { params: { token } });
  //       setPictures(res.data);
  //       setPicturesOptions(Object.keys(res.data));
  //       setSelectedPage("all");
  //     } catch (e) {
  //       console.log(e);
  //       swal(getErrorMessage(e));
  //     }
  //   };
  //   getPictures();
  // }, []);

  // useEffect(() => {
  //   setIndex(0);
  //   if (selectedPage === "all") {
  //     let data = [];
  //     for (const name of picturesOptions) {
  //       data.push(...pictures[name]);
  //     }
  //     setDisplayedPictures(data);
  //   } else {
  //     setDisplayedPictures(pictures?.[selectedPage] || []);
  //   }
  // }, [selectedPage]);

  if (!token) {
    navigate("/");
    return <></>;
  }
  return (
    <div>
      <TopPageThanks></TopPageThanks>
      <div className="title-galery">
        <h1 className="title-h1-galery">
          60 FOIS MERCI !<br /> D'AVOIR ÉTÉ À NOS CÔTÉS POUR MARQUER LES 60 ANS
          D'UNIGRAINS{" "}
        </h1>
        <h2 className="title-h2-galery">
          MERCI À TOUS NOS PARTENAIRES POUR VOTRE CONFIANCE ET VOTRE
          COLLABORATION
        </h2>
      </div>
      <div className="div-video-thank">
        {/* <video width="100%" height="auto" controls className="video-thanks">
          <source
            src="https://cdn4.videas.fr/4472630e9cdf43d8ab559856f04bb44b/unigrains60-aftermovie-vf-mp4-480p.mp4?attachment=true&sig=aca1ab8596e35c038a205b8284b4495bd9c0fa137c24b3f62a19b842afc6977b:1qqE2z:P9g5WFAuKr6B-icMh_RJzh0Ce--Q2kH8iLJJe75HZMM"
            type="video/mp4"
            className="video-thanks"
          />
          Votre navigateur ne supporte pas la vidéo.
        </video> */}
        <div
          className="video-thanks"
          style={{ padding: "56.25% 0 0 0", position: "relative" }}
        >
          <iframe
            width="100%"
            height="100%"
            className="video-thanks"
            src="https://app.videas.fr/embed/media/8a8da6cb-0e21-466c-ad9a-70587d200e34/?title=false&logo=false"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="true"
            style={{ position: "absolute", top: 0, left: 0 }}
            referrerpolicy="unsafe-url"
          ></iframe>
        </div>
      </div>
      {/* <div className="div-buttons-select">
        <button
          disabled={"all" == selectedPage}
          className={
            "all" == selectedPage ? "btn-select-disabled" : "btn-select"
          }
          onClick={() => setSelectedPage("all")}
        >
          Tout
        </button>
        {picturesOptions.map((name, key) => (
          <button
            key={key}
            disabled={name == selectedPage}
            className={
              name == selectedPage ? "btn-select-disabled" : "btn-select"
            }
            onClick={() => setSelectedPage(name)}
          >
            {name}
          </button>
        ))}
      </div>
      <div className="div-galery">
        {displayedPictures
          .filter(
            (p, i) =>
              i >= numberPicturesPerPage * index &&
              i < numberPicturesPerPage * (index + 1)
          )
          .map((p, key) => (
            <Picture
              key={key}
              url={p}
              openZoomedImage={openZoomedImage}
              handleDownload={handleDownload}
              token={token}
            />
          ))}
      </div>
      {zoomedImageUrl && (
        <div className="zoomed-image-modal" onClick={closeZoomedImage}>
          <img src={zoomedImageUrl} alt="Zoomed Image" />
          <button
            className="download-button-zoom"
            onClick={() => handleDownload(zoomedImageUrl)}
          >
            <FaDownload color="white" size={20} />
          </button>
        </div>
      )}
      <div className="div-buttons-select">
        <p className="picture-text">
          Page {index + 1} /{" "}
          {Math.ceil(displayedPictures.length / numberPicturesPerPage)}
        </p>
      </div>
      <div className="div-buttons-select">
        <button
          disabled={index == 0}
          className={index == 0 ? "btn-select-disabled" : "btn-select"}
          onClick={() => setIndex(0)}
        >
          1
        </button>
        <button
          disabled={index == 0}
          className={index == 0 ? "btn-select-disabled" : "btn-select"}
          onClick={() => setIndex((prev) => Math.max(prev - 1, 0))}
        >
          Précédent
        </button>
        <button
          disabled={
            index ==
            Math.ceil(displayedPictures.length / numberPicturesPerPage) - 1
          }
          className={
            index ==
            Math.ceil(displayedPictures.length / numberPicturesPerPage) - 1
              ? "btn-select-disabled"
              : "btn-select"
          }
          onClick={() =>
            setIndex((prev) =>
              Math.min(
                prev + 1,
                Math.ceil(displayedPictures.length / numberPicturesPerPage) - 1
              )
            )
          }
        >
          Suivant
        </button>
        <button
          disabled={
            index ==
            Math.ceil(displayedPictures.length / numberPicturesPerPage) - 1
          }
          className={
            index ==
            Math.ceil(displayedPictures.length / numberPicturesPerPage) - 1
              ? "btn-select-disabled"
              : "btn-select"
          }
          onClick={() =>
            setIndex(
              Math.ceil(displayedPictures.length / numberPicturesPerPage) - 1
            )
          }
        >
          {Math.ceil(displayedPictures.length / numberPicturesPerPage)}
        </button>
      </div> */}

      <div
        className="top-page"
        style={{
          backgroundImage: `url(${imgPrincipale})`,
          backgroundPositionY: "center",
        }}
      >
        <div className="div-frise">
          <img className="frise" src={frise}></img>
          <img className="demi-rond" src={demiRond}></img>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

function Picture({ url, openZoomedImage, handleDownload, token }) {
  const [imageUrl, setImageUrl] = useState();
  useEffect(() => {
    const getBase64 = async () => {
      try {
        const pictureRes = await api.get(url, {
          params: {
            token,
          },
          responseType: "base64",
        });
        setImageUrl(`data:image/jpg;base64,${pictureRes.data}`);
      } catch (e) {
        console.log({ e });
      }
    };
    getBase64();
  }, [url]);

  return (
    <div className="div-img-galery">
      <img
        alt=""
        src={imageUrl}
        className="img-galery"
        onClick={() => {
          openZoomedImage(imageUrl);
        }}
      ></img>
      <button
        className="download-button"
        onClick={() => handleDownload(imageUrl)}
      >
        <FaDownload color="white" size={20} />
      </button>
    </div>
  );
}
