import { TopPage } from "./HeaderPage";
import TitlePages from "./BigTitle";
import "./Contact.css";

export default function HomePage() {
  return (
    <div>
      <TopPage></TopPage>
      <TitlePages value="Cliquez sur le lien reçu par email"></TitlePages>

      <div className="div-contact">
        <p>
          Cet événement est réservé aux personnes ayant reçu une invitation.{" "}
          <br />
          <br />
          Merci de cliquer sur le lien reçu par email !<br />
          <br />
        </p>
        <br />
        <br />
      </div>
    </div>
  );
}
