import {
  Typography,
  Card,
  Chip,
  CardContent,
  Box,
  capitalize,
} from "@mui/material";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { DragItemType } from "../models/DragItemType";
export type Props = {
  id: string;
  index: number;
  firstName: string;
  lastName: string;
  company: string;
  job: string;
  color: string;
  isFrench: boolean;
  isVip: boolean;
  owner: string;
  onMove?: (oldIndex: number, toIndex: number) => void;
};

export const PlacedCardItem = ({
  id,
  index,
  firstName,
  lastName,
  company,
  owner,
  job,
  color,
  isFrench,
  isVip,
  onMove,
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<{ index: number }, unknown, any>({
    accept: DragItemType.unplacedCard,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current || !onMove) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset?.y ?? 0) - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMove(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: DragItemType.unplacedCard,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  if (onMove) {
    drag(drop(ref));
  }
  return (
    <Box ref={ref} sx={{ opacity }}>
      <Card
        sx={{
          position: "relative",
          cursor: "default",
          backgroundColor: "lightgray",
        }}
      >
        <CardContent>
          <Typography variant={"h6"} color={"gray"}>
            {lastName.toUpperCase()}
            {"\u00a0"}
            {capitalize(firstName)}
          </Typography>
          <Typography variant={"body1"} color={"white"}>
            {company}
          </Typography>
          <Typography variant={"body1"} color={"white"}>
            {job}
          </Typography>
          <Typography variant={"body1"} color={"white"}>
            {owner}
          </Typography>
        </CardContent>
        {isVip && (
          <Chip
            label={"VIP"}
            color={"success"}
            size={"small"}
            sx={{ position: "absolute", top: 5, right: 25 }}
          />
        )}

        {isFrench === false && (
          <Chip
            label={"EN"}
            color={"error"}
            size={"small"}
            sx={{ position: "absolute", bottom: 5, right: 25 }}
          />
        )}

        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            width: 18,
            backgroundColor: "darkgray",
          }}
        />
      </Card>
    </Box>
  );
};
