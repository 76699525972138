import { create } from "zustand";
import { persist } from "zustand/middleware";
import api from "../api";

// Store Zustand pour l'état d'authentification de l'utilisateur
// Zustand permet de facilement accéder globalement dans l'application à l'état de connexion de l'utilisateur, persister cet état dans le local storage et mettre à jour l'instance axios avec le token d'authentification
const useAuthStore = create()(
  persist(
    (set, get) => ({
      currentUser: null, // Contient le token d'authentification et le niveau d'accès de l'utilisateur
      isConnected: !!get()?.currentUser, // Indique si l'utilisateur est connecté
      login: (currentUser) => set({ currentUser }), // Fonction pour se connecter
      logout: () => set({ currentUser: null }), // Fonction pour se déconnecter
    }),
    {
      name: "auth-store",
    }
  )
);

const token = useAuthStore.getState().currentUser?.token;
if (token) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

useAuthStore.subscribe((state) => {
  if (state.currentUser === null) {
    delete api.defaults.headers.common.Authorization;
  } else {
    api.defaults.headers.common.Authorization = `Bearer ${state.currentUser.token}`;
  }
});

export default useAuthStore;
