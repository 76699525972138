import "./Title-arrowFR.css";
import fleche from "./img/fleche.png";

export default function TitleArrow({ value }) {
  return (
    <div className="checkbox-form">
      <img src={fleche}></img>

      <h3 className="h3">{value}</h3>
    </div>
  );
}
