import {
  Chip,
  Stack,
  TextField,
  InputAdornment,
  Divider,
  Button,
  Typography,
} from "@mui/material";
import Iconify from "../components/Iconify";
import { UnplacedCardItem } from "./UnplacedCardItem";
import { UnplacedListFilterItem } from "../models/UnplacedFilterItem";
import { UnplacedListData } from "../models/UnplacedListData";
import { searchTextInArray } from "../utils/searchTextInArray";
import React from "react";
import { PlacedCardItem } from "./PlacedCardItem";

export type Props = {
  data: UnplacedListData[];
  placedData: UnplacedListData[];
  onChange: (nextData: UnplacedListData[]) => void;
  onBackHistory: () => void;
  onForwardHistory: () => void;
  canForwardHistory: boolean;
  canBackHistory: boolean;
  onSave: () => void;
};
export const UnplacedListSection = ({
  data,
  placedData,
  canBackHistory,
  canForwardHistory,
  onChange,
  onBackHistory,
  onForwardHistory,
  onSave,
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const colors: { [key: string]: string } = {
    Agriculture: "#7363b9",
    Entreprise: "#519872",
    Intermédiaire: "#D65108",
    Interne: "#FDB940",
    Investisseur: "#92DCE5",
  };

  const getColor = (label: string, isSelected: boolean) => {
    if (colors[label]) {
      return colors[label];
    }
    return isSelected ? "primary" : "default";
  };
  const [search, setSearch] = React.useState("");
  const [contentWidth, setContentWidth] = React.useState(0);
  const [filters, setFilters] = React.useState([
    {
      id: "vip" as const,
      label: "VIP",
      getResult: (item: UnplacedListData) => item.isVip,
      selected: false,
    },
    {
      id: "en" as const,
      label: "EN",
      getResult: (item: UnplacedListData) => !item.isFrench,
      selected: false,
    },
    {
      id: "fr" as const,
      label: "FR",
      getResult: (item: UnplacedListData) => item.isFrench,
      selected: false,
    },

    {
      id: "Agriculture" as const,
      label: "Agriculture",
      getResult: (item: UnplacedListData) => item.job === "Agriculture",
      selected: false,
    },
    {
      id: "Entreprise" as const,
      label: "Entreprise",
      getResult: (item: UnplacedListData) => item.job === "Entreprise",
      selected: false,
    },
    {
      id: "Intermédiaire" as const,
      label: "Intermédiaire",
      getResult: (item: UnplacedListData) => item.job === "Intermédiaire",
      selected: false,
    },
    {
      id: "Interne" as const,
      label: "Interne",
      getResult: (item: UnplacedListData) => item.job === "Interne",
      selected: false,
    },
    {
      id: "Investisseur" as const,
      label: "Investisseur",
      getResult: (item: UnplacedListData) => item.job === "Investisseur",
      selected: false,
    },
  ]);
  const handleFilterChange = (item: UnplacedListFilterItem) => () => {
    setFilters(
      filters.map((filter) => ({
        ...filter,
        selected: filter.id === item.id ? !item.selected : filter.selected,
      }))
    );
  };

  const dataResult = React.useMemo(
    () =>
      data.filter((dataItem) => {
        const textHasBeenFound = searchTextInArray(search, [
          dataItem.firstName,
          dataItem.lastName,
          dataItem.job,
          dataItem.company,
          dataItem.owner,
        ]);

        const filtersResult = filters
          .filter((filter) => filter.selected)
          .reduce(
            (result, filter) => result && filter.getResult(dataItem),
            true
          );
        return textHasBeenFound && filtersResult;
      }),
    [data, search, filters]
  );

  const placedDataResult = React.useMemo(
    () =>
      placedData.filter((dataItem) => {
        const searchString = search.toLowerCase().trim();
        const textHasBeenFound = new RegExp(search, "i").test(
          `${dataItem?.firstName
            ?.toLowerCase()
            .includes(searchString)}${dataItem?.lastName
            ?.toLowerCase()
            .includes(searchString)}${dataItem?.job
            ?.toLowerCase()
            .includes(searchString)}${dataItem?.owner
            ?.toLowerCase()
            .includes(searchString)}${dataItem?.company
            ?.toLowerCase()
            .includes(searchString)}`
        );

        const filtersResult = filters
          .filter((filter) => filter.selected)
          .reduce(
            (result, filter) => result && filter.getResult(dataItem),
            true
          );
        return textHasBeenFound && filtersResult;
      }),
    [placedData, search, filters]
  );

  React.useEffect(() => {
    const listener = () => {
      setContentWidth(ref.current?.offsetWidth ?? 0);
    };
    listener();
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);
  return (
    <Stack ref={ref} alignItems={"stretch"} p={0}>
      <Stack
        spacing={2}
        sx={{
          position: "fixed",
          height: `calc(100vh - 80px)`,
          p: 0,
          width: contentWidth,
        }}
      >
        <Stack>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value ?? "")}
            size={"small"}
            label={"Rechercher"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={"eva:search-outline"} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Divider />
        <Stack
          direction={"row"}
          spacing={2}
          overflow={"auto"}
          flexWrap={"wrap"}
          justifyContent={"center"}
          alignSelf={"center"}
          useFlexGap
        >
          {filters.map((filterItem) => (
            <Chip
              key={filterItem.id}
              label={filterItem.label}
              sx={{
                cursor: "pointer",
                backgroundColor: getColor(
                  filterItem.label,
                  filterItem.selected
                ),
                "& .MuiChip-label": {
                  fontSize: 17,
                  padding: 1,
                },
                border: filterItem.selected ? "3px solid black" : "0px",
              }}
              size={"small"}
              onClick={handleFilterChange(filterItem)}
            />
          ))}
        </Stack>
        <Divider />

        <Stack
          flex={1}
          spacing={3}
          flexGrow={1}
          sx={{ overflow: "auto" }}
          pl={2}
          pb={5}
        >
          {dataResult.map((item, index) => (
            <UnplacedCardItem
              key={item._id}
              id={item._id}
              index={index}
              color={item.color}
              firstName={item.firstName}
              lastName={item.lastName}
              company={item.company}
              job={item.job}
              isVip={item.isVip}
              isFrench={item.isFrench}
              owner={item.owner}
              onMove={(fromIndex, toIndex) => {
                const to = data[toIndex];
                const from = data[fromIndex];

                data[toIndex] = from;
                data[fromIndex] = to;

                onChange([...data]);
              }}
            />
          ))}
          <Divider />
          <Stack spacing={3} pt={0}>
            {!!placedDataResult.length && (
              <Typography variant={"h4"} mt={2}>
                Déjà placés{" "}
                <span style={{ fontWeight: "normal", color: "#666" }}>
                  ({placedData.length})
                </span>
              </Typography>
            )}

            {placedDataResult.map((item, index) => (
              <PlacedCardItem
                key={item._id}
                id={item._id}
                index={index}
                color={item.color}
                firstName={item.firstName}
                lastName={item.lastName}
                company={item.company}
                job={item.job}
                isVip={item.isVip}
                owner={item.owner}
                isFrench={item.isFrench}
              />
            ))}
          </Stack>
        </Stack>
        <Divider />
        <Stack justifyContent={"center"} direction={"row"} spacing={3}>
          <Button
            size={"large"}
            variant={"text"}
            disabled={!canBackHistory}
            onClick={onBackHistory}
          >
            <Iconify
              icon={"eva:refresh-outline"}
              fontSize={30}
              sx={{ transform: "scale(-1, 1)" }}
            />
          </Button>
          <Button
            size={"large"}
            variant={"text"}
            disabled={!canForwardHistory}
            onClick={onForwardHistory}
          >
            <Iconify icon={"eva:refresh-outline"} fontSize={30} />
          </Button>
          <Button
            variant={"contained"}
            style={{ backgroundColor: "#282e67" }}
            size={"large"}
            onClick={onSave}
          >
            Enregistrer
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
