import {
  TableCell,
  TableRow,
  Table,
  Card,
  TableHead,
  TableBody,
  Box,
  Alert,
  Button,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { User } from "../models/User";
import { searchTextInArray } from "../utils/searchTextInArray";
import Iconify from "./Iconify";
import { TableItemRow } from "./TableItemRow";
import { DragItemType } from "..//models/DragItemType";
import useAuthStore from "../../auth/useAuthStore";
export type Props = {
  data: User[];
  tableContainerWidth: number;
  index: number;
  id: string;
  name: string;
  search: string;
  position: number;
  onMove: (fromIndex: number, toIndex: number) => void;
  onAddItem: (item: { id: string; index: number }) => void;
  onDelete: () => void;
  onDeleteUser: (user: User) => void;
  onAcceptUser: (user: User) => void;
  onEditName: (data: { name: string; tableId: string }) => void;
};

export const TABLE_ITEM_HEIGHT = 470;
export const TableItem = ({
  id,
  data,
  name,
  tableContainerWidth,
  index,
  search,
  position,
  onMove,
  onAddItem,
  onDelete,
  onDeleteUser,
  onAcceptUser,
  onEditName,
}: Props) => {
  const { currentUser } = useAuthStore();
  const ref = React.useRef<HTMLDivElement>(null);
  const indexRef = React.useRef({ index });

  const [{ handlerId, isHover }, drop] = useDrop<
    { index: number },
    unknown,
    any
  >({
    accept: [DragItemType.tableCard, DragItemType.unplacedCard],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isHover:
          monitor.getItemType() === DragItemType.unplacedCard &&
          monitor.isOver({ shallow: true }),
      };
    },
    hover(item, monitor) {
      if (!ref.current || monitor.getItemType() !== DragItemType.tableCard) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = indexRef.current.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();

      const hoverClientX = (clientOffset?.x ?? 0) - hoverBoundingRect.left;
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      item.index = hoverIndex;
      indexRef.current.index = dragIndex;
      onMove(dragIndex, hoverIndex);
    },
    drop(item, monitor) {
      if (monitor.getItemType() === DragItemType.unplacedCard) {
        onAddItem?.(item as { id: string; index: number });
      }
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: DragItemType.tableCard,
    item: () => ({ id, index: indexRef.current.index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const searchInRow = React.useCallback(
    (row: User) => {
      if (!search.trim().length) {
        return true;
      }
      const textHasBeenFound = searchTextInArray(search, [
        row.firstName,
        row.lastName,
        row.company,
      ]);
      return textHasBeenFound;
    },
    [search]
  );
  React.useEffect(() => {
    indexRef.current.index = index;
  }, [index]);

  const filteredData = React.useMemo(
    () =>
      data
        .map((item) => ({
          ...item,
          found: searchInRow(item),
        }))
        .slice(0, 12),
    [searchInRow, data]
  );

  const found = React.useMemo(() => {
    const dataFound = filteredData.filter((item) => item.found);
    return !search.trim().length || dataFound.length > 0;
  }, [filteredData, search]);

  return (
    <Box
      mb={3}
      ref={ref}
      sx={{
        opacity,
        filter: found ? undefined : "blur(10px)",
        position: "relative",
        marginRight: 1,
      }}
      data-handler-id={handlerId}
    >
      <Button
        onClick={onDelete}
        sx={{
          position: "absolute",
          zIndex: 0,
          top: -10,
          right: -10,
          p: 0,
          height: 30,
          width: 30,
          borderRadius: 15,
          minWidth: 0,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
        size={"small"}
        variant={"contained"}
        color={"error"}
      >
        <Iconify color={"#FFF"} icon={"eva:trash-outline"} />
      </Button>
      <Card
        sx={{
          width: {
            md: tableContainerWidth / 4.3,
            sm: "100%",
            xs: "100%",
            height: TABLE_ITEM_HEIGHT,
          },
          px: 1,
          py: 2,
          backgroundColor:
            isHover && filteredData.length < 12
              ? "rgba(255,0,0, 0.05)"
              : currentUser.isAdmin
              ? data.length < 7
                ? "#f5b3b3"
                : data.length < 9
                ? "#f5d2bf"
                : ""
              : "",
        }}
      >
        <Stack direction={"row"} alignItems={"center"} marginBottom={2}>
          <Typography mx={2} fontWeight={"bold"}>
            Table #{position + 1}{" "}
            <span style={{ fontWeight: "normal", color: "#666" }}>
              ({data.length})
            </span>
          </Typography>
          <TextField
            placeholder="Nom de la table"
            style={{ width: "50%" }}
            size={"small"}
            value={name}
            onChange={(e) => {
              onEditName({ name: e.currentTarget.value, tableId: id });
            }}
          />
        </Stack>

        <Table size={"small"} sx={{ p: 0, m: 0 }}>
          <TableHead>
            <TableRow
              style={{
                backgroundColor:
                  (position + 1) % 2 === 0 ? "#fdb940" : "#aaa9d4",
              }}
            >
              <TableCell sx={{ p: 2, py: 0.5 }}>Nom</TableCell>
              <TableCell align={"center"} sx={{ p: 0, py: 0.5 }}></TableCell>
              <TableCell align={"center"} sx={{ p: 0, py: 0.5 }}></TableCell>
              <TableCell align={"center"} sx={{ p: 0, py: 0.5 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((item) => (
              <TableItemRow
                key={item._id}
                item={item}
                isSearching={!!search.trim().length}
                isSelected={item.found}
                onDeleteUser={onDeleteUser}
                onAcceptUser={onAcceptUser}
              />
            ))}
          </TableBody>
        </Table>
        {data.length >= 10 && (
          <Box mt={1}>
            <Alert color={"warning"}>
              Personne ne pourra être placé en cas de besoin sur cette table
            </Alert>
          </Box>
        )}
      </Card>
    </Box>
  );
};
